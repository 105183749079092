import { AssetTypeCode, RawAssetTypeCode } from 'types/models/asset-type';
import { PowerMeterChannel } from 'types/models/power-meter';
import SamplePoint, { MergedSamplePoint, RawSamplePoint } from 'types/models/samplePoint';

import parseRawSamplePoint from './parse-raw-samplepoint';

export type AssociatedPowerMeterSamplePoints = {
  energy?: SamplePoint;
  line1Voltage?: SamplePoint;
  line1Current?: SamplePoint;
  line2Voltage?: SamplePoint;
  line2Current?: SamplePoint;
  line3Voltage?: SamplePoint;
  line3Current?: SamplePoint;
};

export type MergedPowerMeterSamplePoint = MergedSamplePoint<AssociatedPowerMeterSamplePoints>;

/** Placement in the merged sample point */
enum MergePlacement {
  ROOT = 'root',
  // All enum values below must match the keys in AssociatedPowerMeterSamplePoints.
  HIDDEN_ENERGY = 'energy',
  HIDDEN_LINE_1_VOLTAGE = 'line1Voltage',
  HIDDEN_LINE_1_CURRENT = 'line1Current',
  HIDDEN_LINE_2_VOLTAGE = 'line2Voltage',
  HIDDEN_LINE_2_CURRENT = 'line2Current',
  HIDDEN_LINE_3_VOLTAGE = 'line3Voltage',
  HIDDEN_LINE_3_CURRENT = 'line3Current'
}

const CHANNEL_TO_MERGED_PLACEMENT: Record<PowerMeterChannel, MergePlacement> = {
  [PowerMeterChannel.LINE_1_VOLTAGE]: MergePlacement.HIDDEN_LINE_1_VOLTAGE,
  [PowerMeterChannel.LINE_1_CURRENT]: MergePlacement.HIDDEN_LINE_1_CURRENT,
  [PowerMeterChannel.LINE_2_VOLTAGE]: MergePlacement.HIDDEN_LINE_2_VOLTAGE,
  [PowerMeterChannel.LINE_2_CURRENT]: MergePlacement.HIDDEN_LINE_2_CURRENT,
  [PowerMeterChannel.LINE_3_VOLTAGE]: MergePlacement.HIDDEN_LINE_3_VOLTAGE,
  [PowerMeterChannel.LINE_3_CURRENT]: MergePlacement.HIDDEN_LINE_3_CURRENT,
  [PowerMeterChannel.ENERGY]: MergePlacement.HIDDEN_ENERGY,
  [PowerMeterChannel.FLAGS]: MergePlacement.ROOT
};

/**
 * Returns true if the given raw & parsed power meter samplePoints are associated.
 * That is, they are connected to the same monitor and carry metrics about the power meter.
 */
const isMatchingPowerMeterSamplePoint = (
  rawPowerMeter: RawSamplePoint,
  parsedPowerMeter: SamplePoint
) => {
  if (parsedPowerMeter.assetTypeId !== AssetTypeCode.POWER_METER) {
    return false;
  }
  const isPowerMeter = [
    RawAssetTypeCode.POWER_METER_FLAGS,
    RawAssetTypeCode.POWER_METER_CURRENT,
    RawAssetTypeCode.POWER_METER_VOLTAGE,
    RawAssetTypeCode.POWER_METER_ENERGY
  ].includes(rawPowerMeter.assetTypeId);
  const isSameMonitor = rawPowerMeter.deviceId === parsedPowerMeter.deviceId;
  return isPowerMeter && isSameMonitor;
};

/** Exported for unit-testing. Do not use directly */
export const mergePowerMeterSamplePoints = (
  rawPowerMeter: RawSamplePoint,
  accumulatedSP: SamplePoint
): MergedPowerMeterSamplePoint => {
  const parsedRawSP: SamplePoint | null = parseRawSamplePoint(rawPowerMeter);
  // No need to merge if the raw sample point is not supported.
  if (!parsedRawSP) return accumulatedSP;

  const placementOfRaw: MergePlacement = CHANNEL_TO_MERGED_PLACEMENT[rawPowerMeter.deviceTags.channel];
  const placementOfAccumulated: MergePlacement = CHANNEL_TO_MERGED_PLACEMENT[accumulatedSP.deviceTags.channel];

  // Unable to determine the placement means invalid sample points, so we return the accumulatedSP.
  if (!placementOfRaw || !placementOfAccumulated) return accumulatedSP;

  // Both cannot be root at the same time, so we just return the existing accumulatedSP.
  if (placementOfRaw === MergePlacement.ROOT && placementOfAccumulated === MergePlacement.ROOT) return accumulatedSP;

  let mergedSP: MergedPowerMeterSamplePoint;

  // A diagram to illustrate the process is available here:
  // https://farmbot-au.atlassian.net/wiki/spaces/DEV/pages/2304049165/Data+Structure#3.-FE---Merge-sample-points
  if (placementOfRaw === MergePlacement.ROOT) {
    mergedSP = parsedRawSP; // Store it to the root
    mergedSP._hidden = { // Store its associated sample points
      ...(accumulatedSP as MergedPowerMeterSamplePoint)._hidden, // Copy the existing associated sample points
      [placementOfAccumulated]: {
        ...accumulatedSP,
        _hidden: undefined
      } // Store the accumulatedSP to the correct placement and remove unnecessary nested associations
    };
  } else {
    mergedSP = accumulatedSP;  // Store it to the root
    mergedSP._hidden = {
      ...(accumulatedSP as MergedPowerMeterSamplePoint)._hidden, // Copy the existing associated sample points
      [placementOfRaw]: parsedRawSP // Store the rawSP to the correct placement
    };
  }

  return mergedSP;
};

/**
 * Takes an array of parsed samplePoints and a raw samplePoint, and
 * returns an array of parsed samplePoints.
 */
export const powerMeterSamplePointReducer = (
  accumulatedSamplePoints: SamplePoint[],
  currentPowerMeter: RawSamplePoint
) => {
  const nextSamplePoints: MergedSamplePoint<SamplePoint | AssociatedPowerMeterSamplePoints>[] = [];
  let merged = false;

  // Scan through the accumulated parsed sample points, if currentPowerMeter
  // pairs with an accumulated sample point, merge them. Otherwise,
  // keep copying SP from accumulatedSamplePoints to nextSamplePoints array.
  for (const accumulatedSP of accumulatedSamplePoints) {
    if (isMatchingPowerMeterSamplePoint(currentPowerMeter, accumulatedSP)) {
      nextSamplePoints.push(
        mergePowerMeterSamplePoints(currentPowerMeter, accumulatedSP)
      );
      merged = true;
    } else {
      nextSamplePoints.push(accumulatedSP);
    }
  }
  // If currentPowerMeter hasn't paired with any SP, parse it then add to the parsed sample points.
  if (!merged) {
    const parsedPowerMeter = parseRawSamplePoint(currentPowerMeter);
    if (parsedPowerMeter) {
      nextSamplePoints.push(parsedPowerMeter);
    }
  }
  return nextSamplePoints;
};