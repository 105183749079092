/**
 * Request data used by all enterprises that doesn't change very often.
 */

import axios from 'axios';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import type { SagaIterator } from 'redux-saga';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

import { setAssetTypes } from 'store/modules/assetTypes/actions';
import {
  setBackOfficeDeviceDataSources,
  setBackOfficeDeviceFilters
} from 'store/modules/backOfficeDevices/actions';
import { DataSource } from 'store/modules/backOfficeDevices/types';
import { RawAssetType } from 'types/models/asset-type';
import { getRequest } from 'utils/redux-saga-requests';
import { parseRawAssetTypes } from 'utils/SamplePoints/asset-types/parse-raw-asset-types';

import { loadAppScaffoldFailure, loadAppScaffoldSuccess } from './actions';
import ActionTypes from './constants';

export function* requestAppScaffold(): SagaIterator {
  try {
    const response = yield call(getRequest, 'core/v1');
    const { deviceFilters, dataSources } = response.data;
    const rawAssetTypes: RawAssetType[] = response.data.assetTypes;
    const backOfficeDataSources = keyBy<DataSource>(dataSources, 'id');

    const parsedAssetTypesData = parseRawAssetTypes(rawAssetTypes);

    yield all([
      put(loadAppScaffoldSuccess(response)),
      put(setAssetTypes(parsedAssetTypesData)),
      put(setBackOfficeDeviceFilters(deviceFilters)),
      put(setBackOfficeDeviceDataSources(backOfficeDataSources))
    ]);
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;

    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );

    yield put(loadAppScaffoldFailure(message, error));
  }
}

export function* watchloadAppScaffoldRequest() {
  yield takeLatest(ActionTypes.LOAD_APP_SCAFFOLD_REQUEST, requestAppScaffold);
}

export default function* AppScaffoldSaga() {
  yield all([fork(watchloadAppScaffoldRequest)]);
}
