import { createSelector } from 'reselect';

import {
  AdvancedDamChartType,
  FlowChartType,
  SimpleDamChartType,
  TankChartType,
  TroughDirectChartType
} from 'components/features/samplePoint/SamplePointSampleChart/sampleChartType.enum';
import { ApplicationRootState } from 'store/types';
import { AssetTypeCode } from 'types/models/asset-type';

import { selectCurrentEnterpriseCountry } from '../enterprise/selectors';
import { selectSites } from '../sites/selectors';

const selectLocalUserSettingsState = (state: ApplicationRootState) =>
  state.localUserSettings;

const makeSelectLocalUserSettingsTankLevelChartType = (
  assetTypeId: AssetTypeCode
) =>
  createSelector(
    [selectLocalUserSettingsState, selectCurrentEnterpriseCountry],
    (localUserSettingsState, enterpriseCountry): TankChartType => {
      const volumeChartType =
        enterpriseCountry === 'USA'
          ? TankChartType.GALLONS
          : TankChartType.LITRES;
      switch (assetTypeId) {
        case AssetTypeCode.FUEL_TANK:
          return localUserSettingsState.fuelLevelChartType ?? volumeChartType;
        case AssetTypeCode.LIQUID_FERTILISER_TANK:
          return (
            localUserSettingsState.liquidFertiliserLevelChartType ??
            volumeChartType
          );
        default:
          return (
            localUserSettingsState.waterLevelChartType ?? TankChartType.LEVEL
          );
      }
    }
  );

const selectLocalUserSettingsFlowChartType = createSelector(
  selectLocalUserSettingsState,
  (localUserSettingsState): FlowChartType =>
    localUserSettingsState.flowChartType ?? FlowChartType.VOLUME_PER_MIN
);

const selectLocalUserSettingsSimpleDamChartType = createSelector(
  selectLocalUserSettingsState,
  (localUserSettingsState): SimpleDamChartType =>
    localUserSettingsState.simpleDamChartType ?? SimpleDamChartType.VOLUME
);

const selectLocalUserSettingsAdvancedDamChartType = createSelector(
  selectLocalUserSettingsState,
  (localUserSettingsState): AdvancedDamChartType =>
    localUserSettingsState.advancedDamChartType ?? AdvancedDamChartType.VOLUME
);

const selectLocalUserSettingsTroughDirectChartType = createSelector(
  selectLocalUserSettingsState,
  (localUserSettingsState) => localUserSettingsState.troughDirectChartType ?? TroughDirectChartType.PERCENT
);

const selectDefaultChartDateRanges = createSelector(
  selectLocalUserSettingsState,
  (localUserSettingsState) => localUserSettingsState.defaultChartDateRanges
);

const selectLocalUserSettingsShowChartList = createSelector(
  selectLocalUserSettingsState,
  (localUserSettingsState) => localUserSettingsState.showChartList
);

const selectLocalUserSettingsShowChartListForPowerMeter = createSelector(
  selectLocalUserSettingsState,
  (localUserSettingsState) => localUserSettingsState.showChartListForPowerMeter
);

const selectDefaultSite = createSelector(
  [selectLocalUserSettingsState, selectSites],
  ({ defaultSiteId }, sites) =>
    defaultSiteId ? sites[defaultSiteId] : Object.values(sites)[0]
);

const selectComparisonSettings = createSelector([selectLocalUserSettingsState],
  (localUserSettingsState) => localUserSettingsState.comparisonSettings);

export {
  selectLocalUserSettingsState,
  makeSelectLocalUserSettingsTankLevelChartType,
  selectLocalUserSettingsFlowChartType,
  selectLocalUserSettingsSimpleDamChartType,
  selectLocalUserSettingsAdvancedDamChartType,
  selectLocalUserSettingsTroughDirectChartType,
  selectDefaultChartDateRanges,
  selectLocalUserSettingsShowChartList,
  selectLocalUserSettingsShowChartListForPowerMeter,
  selectDefaultSite,
  selectComparisonSettings
};
