/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/ban-types */
import { LoadingOutlined, RightOutlined } from '@ant-design/icons';
import { ConfigProvider, List, Table, Typography } from 'antd';
import { ListProps } from 'antd/lib/list';
import { ColumnType, TablePaginationConfig, TableProps } from 'antd/lib/table';
import { ExpandableConfig } from 'antd/lib/table/interface';
import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Resizable } from 'react-resizable';
import { useMediaQuery } from 'react-responsive';
import { useSearchParams } from 'react-router-dom-v5-compat';

import { PAGE_SIZE } from 'constants/list';
import { SEARCH_PARAMS } from 'constants/search-params';
import { appendValuesToQueryString } from 'store/modules/routerUtils/actions';
import { LEGACY_MAX_WIDTH_MOBILE_LANDSCAPE } from 'style/constants';
import classNamesWithBase from 'utils/classnames-with-base';

import './styles.less';

const ResizableTitle = (props: any) => {
  const { onResize, width, ...restProps } = props;

  if (!width || !onResize) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

type RowExpanderProps = {
  expanded: boolean;
  onClick: (evt: React.MouseEvent<HTMLElement>) => void;
  className?: string;
};

const RowExpander = ({ expanded, onClick, className }: RowExpanderProps) => (
  <div
    className={classNames('DefaultTable-row-expander', className)}
    onClick={onClick}
  >
    <RightOutlined
      className={classNames('DefaultTable-row-expander-icon', { expanded })}
      aria-label={`${expanded ? 'Collapse' : 'Expand'} row`}
      aria-expanded={expanded}
    />
  </div>
);

interface Props<RecordType>
  extends Pick<
    TableProps<RecordType>,
    | 'className'
    | 'components'
    | 'bordered'
    | 'expandable'
    | 'rowKey'
    | 'dataSource'
    | 'onRow'
    | 'loading'
    | 'scroll'
    | 'size'
    | 'sticky'
    | 'rowClassName'
  > {
  columns: ColumnType<RecordType>[];
  empty: React.ReactNode;
  mobileListTitleRight?: string;
  mobileListTitleLeft?: string;
  /**
   * Item renderer on mobile view; be sure to pass your own onClick, etc. as needed because it does not
   * by default receive the onRow props from the parent DefaultTable component
   */
  mobileListItem?: ListProps<RecordType>['renderItem'];
  rowSelection?: any;
  /**
   * Pagination props
   */
  pageSize?: number;
  total?: number;
  /**
   * Before implementing this callback,
   * please check the `onChange` in the pagination property of DefaultTable.
   */
  onPaginationChange?: (total: number, from: number, to: number) => void;
  paginationPositions?: Required<TablePaginationConfig>['position'];
  disablePagination?: boolean;
  forceDesktopView?: boolean;
  mobileViewMaxWidth?: number;
  // TODO: remove this prop after https://farmbot.atlassian.net/browse/FMBT-6455, and
  // https://farmbot.atlassian.net/browse/FMBT-6453
  /**
   * Components on old React Router are not using 'useApplyCachedSearchParams' yet,
   * and still rely on the dispatch(appendValuesToQueryString) method.
   */
  isOnOldRouter?: boolean;
}

/**
 * A wrapper around the antd Table with our custom config applied.
 * Includes the following:
 * 1. Custom empty message.
 * 2. Hiding pagination if only 1 page exists.
 * 3. Storing currentPage in query string.
 * 4. If expandable, expand only one row at a time.
 */
function DefaultTable<RecordType extends object>({
  bordered,
  className,
  components = {
    header: {
      cell: ResizableTitle
    }
  },
  expandable,
  rowKey,
  columns,
  dataSource,
  onRow,
  empty,
  loading,
  // Enables horizontal scroll when the table overflows.
  scroll = { x: 0 },
  size = 'large',
  mobileListTitleRight,
  mobileListTitleLeft,
  mobileListItem,
  pageSize = PAGE_SIZE.DEFAULT_TABLE,
  total,
  onPaginationChange,
  paginationPositions = ['bottomRight'],
  sticky,
  rowSelection,
  rowClassName,
  disablePagination = false,
  mobileViewMaxWidth = LEGACY_MAX_WIDTH_MOBILE_LANDSCAPE,
  isOnOldRouter
}: Props<RecordType>): JSX.Element {
  const isTabletOrMobile = useMediaQuery({ maxWidth: mobileViewMaxWidth });
  const [searchParams, setSearchParams] = useSearchParams();

  const currentPage: number = Number(searchParams.get(SEARCH_PARAMS.TABLE.CURRENT_PAGE)) || 1;

  const sortField: string | undefined = searchParams.get(SEARCH_PARAMS.TABLE.SORT_FIELD) || undefined;
  const sortOrder: 'descend' | 'ascend' | undefined = (
    searchParams.get(SEARCH_PARAMS.TABLE.SORT_ORDER) || undefined
  ) as 'descend' | 'ascend' | undefined;

  const dispatch = useDispatch();

  const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);

  /**
   * Once the expanded row is added to the DOM, it takes up the tr:last-child slot,
   * hence we use a dedicated class name to style the last top-level row.
   * See the DefaultTable/styles.less file for more details.
   */
  const handleExpandedRowStyle = (expanded: boolean) => {
    const topLevelRows = document.querySelectorAll('.DefaultTable tbody tr.top-level');
    const lastTopLevelRow = Array.from(topLevelRows)[topLevelRows.length - 1];
    if (expanded) {
      // When the row is expanded, it is no longer the last row in the table (so rounded borders etc. do not apply).
      lastTopLevelRow.classList.remove('last-top-level-row');
    } else {
      lastTopLevelRow.classList.add('last-top-level-row');
    }
  };

  const handleExpandOneRowAtATime = useCallback((expanded: boolean, record: RecordType) => {
    if (expandable?.onExpand) {
      // If onExpand is provided from consumer of DefaultTable, call it
      expandable.onExpand(expanded, record);
    }
    if (!expanded) {
      setExpandedRowKeys([]);
    } else {
      const key = typeof rowKey === 'function' ? rowKey(record) : record[rowKey || 'key'];
      setExpandedRowKeys([key]);
    }
    handleExpandedRowStyle(expanded);
  }, [expandable, rowKey]);

  const renderExpandIcon: ExpandableConfig<RecordType>['expandIcon'] = useCallback(({ expanded, onExpand, record }) => {
    return (
      <RowExpander
        onClick={evt => { evt.stopPropagation(); onExpand(record, evt); }}
        expanded={expanded}
      />
    );
  }, []);

  useEffect(() => {
    if (onPaginationChange) {
      const from = Math.min(
        Math.max((currentPage - 1) * pageSize + 1, 1),
        total || 0
      );
      const to = Math.min(currentPage * pageSize, total || 0);
      onPaginationChange(total || 0, from, to);
    }
  }, [onPaginationChange, pageSize, total, currentPage]);

  const rowClassNameHandler = useCallback(
    (record, index, indent) => {
      let actualRowClassName = 'cursor-pointer top-level';
      if (rowClassName) {
        actualRowClassName +=
          typeof rowClassName === 'string'
            ? ` ${rowClassName}`
            : ` ${rowClassName(record, index, indent)}`;
      }
      return actualRowClassName;
    },
    [rowClassName]
  );

  const formattedColumns = useMemo(
    () =>
      columns?.map((column) => {
        const columnCopy = { ...column };
        const { key, dataIndex } = column;
        const columnId = key || dataIndex;
        if (columnId === sortField) {
          columnCopy.sortOrder = sortOrder;
        }
        return columnCopy;
      }),
    [columns, sortField, sortOrder]
  );

  const pagination = useMemo(() => {
    const totalRecords = total || dataSource?.length || 0;
    const hidePagination = disablePagination || totalRecords <= pageSize;
    if (hidePagination) return false;
    return {
      position: paginationPositions,
      current: currentPage || 1,
      total,
      onChange: (page) => {
        // Scroll to top on page change
        window.scrollTo(0, 0);

        if (isOnOldRouter) {
          dispatch(appendValuesToQueryString({ currentPage: page }));
          return;
        }
        setSearchParams((prev) => {
          const newSearchParams = new URLSearchParams(prev);
          newSearchParams.set(SEARCH_PARAMS.TABLE.CURRENT_PAGE, page.toString());
          return newSearchParams;
        });
      },
      pageSize,
      showSizeChanger: false,
      style: {
        marginRight: isTabletOrMobile ? 10 : undefined
      }
    };
  }, [
    currentPage,
    dataSource?.length,
    disablePagination,
    dispatch,
    isOnOldRouter,
    isTabletOrMobile,
    pageSize,
    paginationPositions,
    setSearchParams,
    total
  ]);

  const DesktopTable = useCallback(
    (forMobilePrinting?: boolean) => (
      <ConfigProvider renderEmpty={() => empty}>
        <Table
          className={classNamesWithBase(
            'DefaultTable',
            {
              'mobile-print': !!forMobilePrinting,
              'with-pagination': !!pagination
            },
            className
          )}
          bordered={bordered}
          expandable={{
            ...expandable,
            expandIcon: expandable && renderExpandIcon, // Do not show expand icon if expandable is not provided
            expandedRowKeys,
            onExpand: handleExpandOneRowAtATime
          }}
          rowKey={rowKey}
          columns={formattedColumns}
          components={components}
          dataSource={dataSource}
          showSorterTooltip={false}
          loading={
            loading
              ? { indicator: <LoadingOutlined style={{ fontSize: 24 }} spin /> }
              : false
          }
          scroll={scroll}
          pagination={pagination}
          rowClassName={rowClassNameHandler}
          size={size}
          onRow={onRow}
          onChange={(_pagination, _filters, sorter) => {
            if (!Array.isArray(sorter)) {
              const { order, columnKey, field } = sorter;
              const key = columnKey || field;
              if (sortOrder !== order || sortField !== key) {
                if (isOnOldRouter) {
                  dispatch(
                    appendValuesToQueryString({
                      sortOrder: order,
                      sortField: key,
                      // Reset page when sorting
                      currentPage: 1
                    })
                  );
                  return;
                }
                setSearchParams((prev) => {
                  const newSearchParams = new URLSearchParams(prev);

                  if (order) newSearchParams.set(SEARCH_PARAMS.TABLE.SORT_ORDER, order);
                  else newSearchParams.delete(SEARCH_PARAMS.TABLE.SORT_ORDER);

                  if (key) newSearchParams.set(SEARCH_PARAMS.TABLE.SORT_FIELD, key.toString());
                  else newSearchParams.delete(SEARCH_PARAMS.TABLE.SORT_FIELD);

                  newSearchParams.set(SEARCH_PARAMS.TABLE.CURRENT_PAGE, '1'); // Reset page when sorting
                  return newSearchParams;
                });
              }
            }
          }}
          sticky={sticky}
          rowSelection={rowSelection}
        />
      </ConfigProvider>
    ),
    [
      bordered,
      className,
      components,
      dataSource,
      dispatch,
      empty,
      expandable,
      expandedRowKeys,
      formattedColumns,
      handleExpandOneRowAtATime,
      isOnOldRouter,
      loading,
      onRow,
      pagination,
      renderExpandIcon,
      rowClassNameHandler,
      rowKey,
      rowSelection,
      scroll,
      size,
      setSearchParams,
      sortField,
      sortOrder,
      sticky
    ]
  );

  const MobileListHeader = () => {
    return (
      <div className={classNames('DefaultTable-mobile-header', { 'with-expand-column': !!expandable })}>
        <Typography.Text>{mobileListTitleLeft}</Typography.Text>
        <Typography.Text>{mobileListTitleRight}</Typography.Text>
      </div>
    );
  };

  // Memoize with useCallback to ensure smooth transition of the expand icon.
  // Without memoization, the DOM element is recreated on each render, voiding the rotation animation.
  const renderExpandableItem: ListProps<RecordType>['renderItem'] = useCallback((record: RecordType, index: number) => {
    if (!expandable || !mobileListItem) return null;

    const key: React.Key = typeof rowKey === 'function' ? rowKey(record) : record[rowKey || 'key'];
    const isItemExpanded = expandedRowKeys.includes(key);
    const onExpand = () => {
      const newExpandedState = !isItemExpanded;
      handleExpandOneRowAtATime(newExpandedState, record);
    };
    return (
      <List.Item className="DefaultTable-mobile-row expandable">
        <div className="top-level">
          <RowExpander expanded={isItemExpanded} onClick={onExpand} />
          <div className="expander-pusher w-full" onClick={onRow ? onRow(record, index).onClick : undefined}>
            {mobileListItem(record, index)}
          </div>
        </div>
        {isItemExpanded && expandable.expandedRowRender && (
          <div className="expanded-content">
            {expandable.expandedRowRender(record, index, 0, isItemExpanded)}
          </div>
        )}
      </List.Item>
    );
  }, [expandable, expandedRowKeys, handleExpandOneRowAtATime, mobileListItem, onRow, rowKey]);

  // On mobile it's a list with a hidden table component for printing; On desktop
  // it's table only.
  if (isTabletOrMobile && mobileListItem) {
    const renderItem: ListProps<RecordType>['renderItem'] = (record: RecordType, index: number) => (
      <List.Item className="DefaultTable-mobile-row" onClick={onRow ? onRow(record, index).onClick : undefined}>
        {mobileListItem(record, index)}
      </List.Item>
    );
    return (
      <>
        {DesktopTable(true)}
        <List
          className="DefaultTable-mobile"
          // TODO: handle dataSource readonly properly
          dataSource={dataSource ? [...dataSource] : dataSource}
          locale={{ emptyText: empty }}
          header={
            (mobileListTitleLeft || mobileListTitleRight) && (
              <MobileListHeader />
            )
          }
          // In the first release of Power Meter, we don't have resources to implement the mobile-version expandable
          // for Devices, nor do we have UI design for it, so we're skipping it for now.
          renderItem={(expandable && className !== 'Devices-table') ? renderExpandableItem : renderItem}
        />
      </>
    );
  }

  return DesktopTable();
}

export default DefaultTable;
