/**
 * Request all the samples for a given set of sample points and a date range
 */

import { all, fork, takeLatest } from 'redux-saga/effects';

import { requestComparativeSamples } from './requestComparativeSamples';
import { requestInternalSamples } from './requestInternalSamples';
import { requestIntervalSamples } from './requestIntervalSamples';
import { requestMachineControlSamples } from './requestMachineControlSamples';
import { requestSamples } from './requestSamples';
import ActionTypes from '../constants';

export function* watchLoadAssetSamplesRequest() {
  yield takeLatest(
    ActionTypes.LOAD_SAMPLE_POINT_SAMPLES_REQUEST,
    requestSamples
  );
}

export function* watchLoadIntervalSamplesRequest() {
  yield takeLatest(
    ActionTypes.LOAD_INTERVAL_SAMPLES_REQUEST,
    requestIntervalSamples
  );
}

export function* watchLoadMachineControlSamplesRequest() {
  yield takeLatest(
    ActionTypes.LOAD_MACHINE_CONTROL_SAMPLES_REQUEST,
    requestMachineControlSamples
  );
}

export function* watchLoadAssetInternalSamplesRequest() {
  yield takeLatest(
    ActionTypes.LOAD_INTERNAL_SAMPLES_REQUEST,
    requestInternalSamples
  );
}

function* watchLoadComparativeSamplesRequest() {
  yield takeLatest(
    ActionTypes.LOAD_COMPARATIVE_SAMPLES_REQUEST,
    requestComparativeSamples
  );
}

export default function* samplesSaga() {
  yield all([
    fork(watchLoadAssetSamplesRequest),
    fork(watchLoadAssetInternalSamplesRequest),
    fork(watchLoadIntervalSamplesRequest),
    fork(watchLoadMachineControlSamplesRequest),
    fork(watchLoadComparativeSamplesRequest)
  ]);
}
