import AssetType, {
  AssetTypeCode,
  RawAssetType,
  RawAssetTypeCode
} from 'types/models/asset-type';
import { isRanchbot } from 'utils/is-x-bot';

/**
 * A mapping of raw asset types to those supported by the frontend.
 * Unsupported types map to undefined.
 * Some raw asset types (e.g. soil moisture & temp) may map to the same frontend
 * asset type (soil).
 */
// TODO: this logic control is so hidden
export const RAW_TO_PARSED_ASSET_TYPE_CODE: Record<RawAssetTypeCode, AssetTypeCode | undefined> = {
  [RawAssetTypeCode.UNKNOWN]: undefined,
  [RawAssetTypeCode.WATER_TANK]: AssetTypeCode.WATER_TANK,
  [RawAssetTypeCode.ELECTRIC_FENCE]: undefined,
  [RawAssetTypeCode.RAIN_GAUGE]: AssetTypeCode.RAIN_GAUGE,
  [RawAssetTypeCode.TROUGH]: AssetTypeCode.TROUGH,
  // Diesel & Petrol are both treated as Fuel in the frontend.
  // From Apr 2022, petrol level sensor is indefinitely suspended.
  [RawAssetTypeCode.DIESEL_TANK]: AssetTypeCode.FUEL_TANK,
  [RawAssetTypeCode.PETROL_TANK]: AssetTypeCode.FUEL_TANK,
  [RawAssetTypeCode.PIPE]: AssetTypeCode.PIPE,
  [RawAssetTypeCode.LINE_PRESSURE]: AssetTypeCode.LINE_PRESSURE,
  [RawAssetTypeCode.DAM]: AssetTypeCode.DAM,
  [RawAssetTypeCode.BORE]: AssetTypeCode.BORE,
  [RawAssetTypeCode.LIQUID_FERTILISER_TANK]:
    AssetTypeCode.LIQUID_FERTILISER_TANK,
  // SOIL_MOISTURE and SOIL_TEMP are merged into one asset type.
  [RawAssetTypeCode.SOIL_MOISTURE]: AssetTypeCode.SOIL,
  [RawAssetTypeCode.SOIL_TEMP]: AssetTypeCode.SOIL,
  [RawAssetTypeCode.CAMERA]: AssetTypeCode.CAMERA,
  // SAFETY_CHECK_IN_VISIT and SAFETY_CHECK_IN_SOS are merged into one asset
  // type.
  [RawAssetTypeCode.SAFETY_CHECK_IN_VISIT]: AssetTypeCode.SAFETY_CHECK_IN,
  [RawAssetTypeCode.SAFETY_CHECK_IN_SOS]: AssetTypeCode.SAFETY_CHECK_IN,
  [RawAssetTypeCode.MACHINE_CONTROL]: AssetTypeCode.MACHINE_CONTROL,
  // POWER_METER_FLAGS, _VOLTAGE, _CURRENT, and _ENERGY are merged into one asset type.
  [RawAssetTypeCode.POWER_METER_FLAGS]: AssetTypeCode.POWER_METER,
  [RawAssetTypeCode.POWER_METER_VOLTAGE]: AssetTypeCode.POWER_METER,
  [RawAssetTypeCode.POWER_METER_CURRENT]: AssetTypeCode.POWER_METER,
  [RawAssetTypeCode.POWER_METER_ENERGY]: AssetTypeCode.POWER_METER,
  // Battery/Internal samplePoints are not supported in the same way as other
  // asset types.
  [RawAssetTypeCode.INTERNAL_SENSOR]: undefined,
  // Integrated AssetTypes
  [RawAssetTypeCode.SECURITY_CAMERA]: AssetTypeCode.SECURITY_CAMERA
};

/** To show the user-friendly asset type name in the UI. */
const parseRawAssetType = (
  rawAssetType: RawAssetType
): AssetType | undefined => {
  const id = RAW_TO_PARSED_ASSET_TYPE_CODE[rawAssetType.id];
  if (!id) return undefined;

  switch (id) {
    case AssetTypeCode.BORE:
      if (isRanchbot()) {
        return {
          id,
          name: 'Well',
          description: 'Wells'
        };
      }
      return {
        id,
        name: rawAssetType.name,
        description: rawAssetType.description
      };
    case AssetTypeCode.FUEL_TANK:
      return {
        id,
        name: 'Fuel Tank',
        description: 'Fuel Tanks'
      };
    case AssetTypeCode.LIQUID_FERTILISER_TANK:
      if (isRanchbot()) {
        return {
          id,
          name: 'Liquid Fertilizer',
          description: 'Liquid Fertilizer'
        };
      }
      return {
        id,
        name: rawAssetType.name,
        description: rawAssetType.description
      };
    case AssetTypeCode.SOIL:
      return {
        id,
        name: 'Soil',
        description: 'Soil'
      };
    case AssetTypeCode.POWER_METER:
      return {
        id,
        name: 'Power Meter',
        description: 'Power'
      };
    case AssetTypeCode.SAFETY_CHECK_IN:
      return {
        id,
        name: 'Safety Check-in',
        description: 'Check in & SOS'
      };
    case AssetTypeCode.DAM:
      if (isRanchbot()) {
        return {
          id,
          name: 'Reservoir',
          description: 'Reservoirs & Ponds'
        };
      }
      return {
        id,
        name: 'Dam',
        description: 'Dams & Turkey Nests'
      };
    default:
      return {
        id,
        name: rawAssetType.name,
        description: rawAssetType.description
      };
  }
};

export const parseRawAssetTypes = (rawAssetTypes: RawAssetType[]): Record<AssetTypeCode, AssetType> => {
  return rawAssetTypes.reduce((acc, rawAssetType) => {
    const parsedAssetTypeCode = RAW_TO_PARSED_ASSET_TYPE_CODE[rawAssetType.id];
    const parsedAssetType = parseRawAssetType(rawAssetType);

    return parsedAssetTypeCode !== undefined
      ? { ...acc, [parsedAssetTypeCode]: parsedAssetType }
      : acc;
  }, {} as Record<AssetTypeCode, AssetType>);
};