import moment from 'moment-timezone';

import { MIDNIGHT_HOUR } from 'constants/samplePoint';
import { sampleIntervalToTimeUnit } from 'constants/time';
import { SampleInterval } from 'store/modules/samples/types';

/**
 * Converts the sampleDate from the backend response into date(s) consumed by store/samples module.
 * @returns {Object} prevDate, date - the start and end of the sample period.
 *
 * When dailyResetTime === 12AM, sampleDate 06-13 0:00 means the sample is captured from 06-13 0:00 (to however long
 * the interval is).
 * However, when it is !== 12AM, sampleDate 06-13 9:00 means the sample is captured from 06-12 9:00, so we must
 * subtract one day.
 * Note: this dailyResetTime has no effect when interval = hourly.
 */
export const getIntervalSamplePeriodDates = (
  sampleDate: number,
  sampleInterval: SampleInterval,
  dailyResetTime: number,
  siteTimezoneCode: string
) => {
  const unitOfTime = sampleIntervalToTimeUnit[sampleInterval];

  const prevDate = sampleDate;
  const date = moment(prevDate).tz(siteTimezoneCode).add(1, unitOfTime).valueOf() - 1;

  if (dailyResetTime === MIDNIGHT_HOUR || sampleInterval === SampleInterval.HOURLY) {
    // dailyResetTime has no effect
    return {
      prevDate,
      date
    };
  } else {
    // dailyResetTime has effect
    return {
      prevDate: moment(prevDate).tz(siteTimezoneCode).subtract(1, 'day').valueOf(),
      date: moment(date).tz(siteTimezoneCode).subtract(1, 'day').valueOf()
    };
  }
};

/**
 * Converts the date(s) in the sample Redux store into date(s) used by the interval sample chart
 * @returns {Object} timeAxisValue, startedAt, endedAt.
 * `timeAxisValue` is used to locate the sample on the time axis.
 * `startedAt` and `endedAt` are used by the tooltip, showing the actual sample period.
 */
export const getIntervalSampleChartDates = (
  sample: { prevDate: number, date: number, interval: SampleInterval },
  dailyResetTime: number,
  timezoneCode: string
) => {
  const timeAxisValue = dailyResetTime === MIDNIGHT_HOUR || sample.interval === SampleInterval.HOURLY
    ? sample.prevDate
    : moment(sample.prevDate).tz(timezoneCode).add(1, 'day').valueOf();

  return {
    timeAxisValue,
    startedAt: sample.prevDate,
    endedAt: sample.date
  };
};