import axios from 'axios';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import moment from 'moment-timezone';
import { all, call, put, select } from 'redux-saga/effects';

import Sample, { IntervalSample } from 'types/models/sample';
import { getRequest } from 'utils/redux-saga-requests';
import { getIntervalSamplePeriodDates } from 'utils/Sample/get-interval-sample-dates';

import { parseSample } from './utils';
import { selectCurrentEnterpriseCountry } from '../../enterprise/selectors';
import {
  loadIntervalSamples,
  loadIntervalSamplesFailure,
  loadIntervalSamplesSuccess,
  setSamplePointSamples
} from '../actions';

export function* requestIntervalSamples(
  action: ReturnType<typeof loadIntervalSamples>
) {
  const {
    assetTypeId,
    dailyResetTime,
    endDate,
    interval,
    override,
    samplePointId,
    samplePointSid,
    siteTimezoneCode,
    startDate
  } = action.payload;
  const country: string = yield select(selectCurrentEnterpriseCountry);
  const startDateString = moment(startDate).tz(siteTimezoneCode).format();
  const endDateString = moment(endDate).tz(siteTimezoneCode).format();
  try {
    const { data: intervalSamples } = yield call(getRequest, `/samplepoint/${samplePointSid}/v2/hourly-samples`, {
      params: { startDate: startDateString, endDate: endDateString, interval }
    });

    const parsedSamples: Sample[] = intervalSamples.map((intervalSample: IntervalSample) => {
      const { prevDate, date } = getIntervalSamplePeriodDates(
        intervalSample.date,
        intervalSample.interval,
        dailyResetTime,
        siteTimezoneCode
      );
      return {
        ...parseSample(intervalSample as Sample, samplePointId, assetTypeId, country),
        id: intervalSample.date,
        date,
        prevDate
      };
    });

    yield all([
      put(loadIntervalSamplesSuccess(intervalSamples)),
      put(setSamplePointSamples(keyBy(parsedSamples, 'id'), override))
    ]);
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    let message;
    if (error.message === 'Network Error') {
      message = 'Network Error';
    } else {
      message = get(
        error,
        'response.data.message',
        'Sorry, something went wrong.'
      );
    }

    yield put(loadIntervalSamplesFailure(message, error));
  }
}
