import mergeDualStatusesAndCodes from 'store/modules/controlPoints/helpers';
import { parseStatusCode } from 'store/modules/controlPoints/saga';
import { MachineControlStatus } from 'store/modules/controlPoints/types';
import SamplePoint, { MergedSamplePoint } from 'types/models/samplePoint';
import SamplePointStatistic, { MachineControlStatistic } from 'types/models/samplePointsStatistic';

export function getMergedStatisticForMachineControl(
  allStatistics: Record<number, SamplePointStatistic>,
  mainSamplePoint: SamplePoint
): MachineControlStatistic {
  const primaryMachineControlStatistic = allStatistics[mainSamplePoint.id] as MachineControlStatistic;

  const secondaryMachineControl = (mainSamplePoint as MergedSamplePoint)._hidden;
  // Dual. Merge primary and secondary machine control statuses.
  if (secondaryMachineControl) {
    const secondaryMachineControlStatus = allStatistics[secondaryMachineControl.id] as MachineControlStatistic;
    const [mergedStatus, mergedStatusCode] = mergeDualStatusesAndCodes(
      primaryMachineControlStatistic.status ?? MachineControlStatus.ERROR,
      primaryMachineControlStatistic.statusCode,
      secondaryMachineControlStatus.status ?? MachineControlStatus.ERROR,
      secondaryMachineControlStatus.statusCode
    );

    return {
      ...primaryMachineControlStatistic,
      _status: primaryMachineControlStatistic.status,
      _statusCode: primaryMachineControlStatistic.statusCode,
      status: mergedStatus,
      statusCode: mergedStatusCode,
      statusText: parseStatusCode(mergedStatusCode)
    } as MachineControlStatistic;
  }
  // Single / Double
  return {
    ...primaryMachineControlStatistic,
    _status: primaryMachineControlStatistic.status,
    _statusCode: primaryMachineControlStatistic.statusCode,
    statusText: parseStatusCode(primaryMachineControlStatistic.statusCode)
  } as MachineControlStatistic;
}