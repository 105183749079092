import { PowerMeterStatus } from 'types/models/power-meter';
import Sample from 'types/models/sample';

import { type BitGroup } from './bit';
import { parsePowerMeterFlagsSample } from './flags-parser';

// ==============================
// INTERNAL HELPERS
// ==============================
enum DeviceBitPattern {
  OK = '0000',
  SERIAL_BUS_COMMS_FAILURE = '0001',
  SERIAL_BUS_BAD_CRC = '0010',
  FIRST_SET_OF_VALUES_ON_BOOT = '0011',
  TIME_BASED_TRANSMIT = '0100'
}

const isDeviceBitGroupError = (deviceBitGroup: BitGroup | null): boolean => {
  return deviceBitGroup !== DeviceBitPattern.OK
    && deviceBitGroup !== DeviceBitPattern.FIRST_SET_OF_VALUES_ON_BOOT
    && deviceBitGroup !== DeviceBitPattern.TIME_BASED_TRANSMIT;
};

const determineMeterStatusFromDeviceBits = (
  deviceBitGroup: BitGroup | null,
  packetHasVoltageAndCurrent: boolean,
  previousDeviceBitGroup: BitGroup | null
): PowerMeterStatus => {
  if (!isDeviceBitGroupError(deviceBitGroup)) {
    return PowerMeterStatus.ONLINE;
  }
  if (!packetHasVoltageAndCurrent) {
    return PowerMeterStatus.OFFLINE;
  }
  const wasPreviouslyBlackout = isDeviceBitGroupError(previousDeviceBitGroup);
  if (wasPreviouslyBlackout) {
    // Previously was offline, now it's online
    return PowerMeterStatus.ONLINE;
  }
  // Previously was online, now it's offline
  return PowerMeterStatus.OFFLINE;
};

// ==============================
// EXPORTS
// ==============================
export const getMeterStatusAgainstFlags = (
  flagsSampleValue: Sample['rwValue'],
  packetHasVoltageAndCurrent: boolean,
  previousFlagsSampleValue: Sample['rwValue']
): PowerMeterStatus => {
  const { device: deviceBitGroup } = parsePowerMeterFlagsSample(flagsSampleValue) || {};
  const { device: previousDeviceBitGroup } = parsePowerMeterFlagsSample(previousFlagsSampleValue) || {};
  return determineMeterStatusFromDeviceBits(
    deviceBitGroup ?? null,
    packetHasVoltageAndCurrent,
    previousDeviceBitGroup ?? null
  );
};