import { ComparisonPeriod } from 'store/modules/localUserSettings/types';

export type CommonDateRangeKey = 'hour.48' | 'day.7' | 'day.14' | 'day.30';
export type ExtendedDateRangeKey = 'hour.48' | 'day.7' | 'day.14' | 'day.30' | 'day.60' | 'week.13' | 'month.12' | 'month.24';
export type SafetyCheckInDateRangeKey = 'month.1';
export type CiboDateRangeKey = 'day.30' | 'month.3' | 'month.6' | 'month.12';

export const CHART_DEFAULT_DATE_RANGE: CommonDateRangeKey = 'day.14';
export const RAIN_GAUGE_CHART_DEFAULT_DATE_RANGE: ExtendedDateRangeKey = 'day.30';
export const FLOW_CHART_DEFAULT_DATE_RANGE: CommonDateRangeKey = 'hour.48';
export const SAFETY_CHECK_IN_DEFAULT_DATE_RANGE: SafetyCheckInDateRangeKey = 'month.1';
export const CIBO_CHART_DEFAULT_DATE_RANGE: CiboDateRangeKey = 'month.12';
export const POWER_METER_CHART_DEFAULT_DATE_RANGE: ExtendedDateRangeKey = 'day.7';

export const COMMON_DATE_RANGE_KEYS: CommonDateRangeKey[] = ['hour.48', 'day.7', 'day.14', 'day.30'];
export const EXTENDED_DATE_RANGE_KEYS: ExtendedDateRangeKey[] = ['hour.48', 'day.7', 'day.14', 'day.30', 'day.60', 'week.13', 'month.12', 'month.24'];

export const DATE_RANGE_KEY_TO_LABEL: Record<
  | CommonDateRangeKey
  | ExtendedDateRangeKey
  | SafetyCheckInDateRangeKey,
  string> = {
  'hour.48': '48 Hours',
  'day.7': '7 Days',
  'day.14': '14 Days',
  'day.30': '30 Days',
  'day.60': '60 Days',
  'week.13': '13 Weeks',
  'month.1': '1 Month',
  'month.12': '12 Months',
  'month.24': '24 Months'
};

export const COMPARISON_PERIOD_TO_DATE_RANGE_KEY: Record<ComparisonPeriod, ExtendedDateRangeKey> = {
  [ComparisonPeriod.DAY_30]: 'day.30',
  [ComparisonPeriod.WEEK_13]: 'week.13',
  [ComparisonPeriod.MONTH_12]: 'month.12',
  [ComparisonPeriod.DAY_30_LAST_YEAR]: 'day.30'
};
