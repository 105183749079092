import { EventId, IPCameraEvent, SamplePointEvent } from 'types/models/event';
import { UserId } from 'types/models/user';

export type NotificationId = number;

export enum NotificationStatus {
  ATTEMPTED = 0, // This should not be used for UI type notifications. They are created with SENT status.
  SENT = 1,
  ACKNOWLEDGED = 2,
  DELIVERY_FAILED = 3
}

export enum NotificationType {
  UI = 1,
  EMAIL = 2,
  SMS = 3
}

export enum NotificationEventType {
  IP_CAMERA_EVENT = 'ip-camera-event',
  FRED_EVENT = 'fred-event',
  SAMPLE_POINT_EVENT = 'sample-point-event'
}

export default interface Notification<E extends SamplePointEvent | IPCameraEvent = SamplePointEvent | IPCameraEvent> {
  id: NotificationId;
  event: E;
  eventId: EventId;
  eventType: NotificationEventType;
  /** uiText for NotificationListItem */
  message: string;
  status: NotificationStatus;
  type: NotificationType;
  userId: UserId;
}
