import { AssetTypeCode } from 'types/models/asset-type';
import SamplePoint, { RawSamplePoint, SensorTagId } from 'types/models/samplePoint';

import parseRawSamplePoint from '../parse-raw-samplepoint';

/**
 * Takes a raw machine control sample point and a parsed machine control sample
 * point, and merges them into a single parsed sample point.
 */
const mergeMachineControls = (
  rawMachineControl: RawSamplePoint,
  parsedMachineControl: SamplePoint
): SamplePoint => {
  const isRawMachineControlSecondary =
    rawMachineControl.sensorTags.id === SensorTagId.SECONDARY_MACHINE_CONTROL;
  const [primaryMachineControl, secondaryMachineControl] =
    isRawMachineControlSecondary
      ? [
        parsedMachineControl,
        parseRawSamplePoint(rawMachineControl) as SamplePoint
      ]
      : [
        parseRawSamplePoint(rawMachineControl) as SamplePoint,
        parsedMachineControl
      ];
  return {
    ...primaryMachineControl,
    _hidden: secondaryMachineControl
  } as SamplePoint;
};

const machineControlsArePaired = (
  rawMachineControl: RawSamplePoint,
  parsedMachineControl: SamplePoint
) => {
  if (parsedMachineControl.assetTypeId !== AssetTypeCode.MACHINE_CONTROL) {
    return false;
  }
  if (parsedMachineControl.deviceId !== rawMachineControl.deviceId) {
    return false;
  }
  if (
    rawMachineControl.sensorTags.id === SensorTagId.PRIMARY_MACHINE_CONTROL &&
    parsedMachineControl.sensorTags.id === SensorTagId.SECONDARY_MACHINE_CONTROL
  ) {
    return true;
  }
  if (
    rawMachineControl.sensorTags.id === SensorTagId.SECONDARY_MACHINE_CONTROL &&
    parsedMachineControl.sensorTags.id === SensorTagId.PRIMARY_MACHINE_CONTROL
  ) {
    return true;
  }
  return false;
};

/**
 * Takes an array of parsed samplePoints and a raw samplePoint, and
 * returns an array of parsed samplePoints.
 */
export const machineControlSamplePointReducer = (
  accumulatedSamplePoints: SamplePoint[],
  currentMachineControl: RawSamplePoint
): SamplePoint[] => {
  const nextSamplePoints: SamplePoint[] = [];
  let merged = false;

  // Scan through the accumulated parsed sample points, if currentMachineControl
  // pairs with an accumulated sample point (machine control), merge them. Otherwise,
  // keep copying SP from accumulatedSamplePoints to nextSamplePoints array.
  for (const samplePoint of accumulatedSamplePoints) {
    if (
      !merged &&
      machineControlsArePaired(currentMachineControl, samplePoint)
    ) {
      nextSamplePoints.push(
        mergeMachineControls(currentMachineControl, samplePoint)
      );
      merged = true;
    } else {
      nextSamplePoints.push(samplePoint);
    }
  }
  // If currentMachineControl hasn't paired with any SP, parse it as a single/double machine
  // control into the parsed sample points.
  if (!merged) {
    const parsedMachineControl = parseRawSamplePoint(currentMachineControl);
    if (parsedMachineControl) {
      nextSamplePoints.push(parsedMachineControl);
    }
  }

  return nextSamplePoints;
};
