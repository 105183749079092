const RAISE_100_PERCENT: number = 1;
const FALL_100_PERCENT: number = -1;
const NO_CHANGE: number = 0;

/**
 * Returns a signed number representing the trend between two values, e.g.
 * -1.2 means a 120% decrease, and 0.8 means an 80% increase.
 * Handles edge cases where the previous value or the current value or both are zero.
 *
 * @param previousValue - The initial value to compare from.
 * @param currentValue - The new value to compare to.
 * @returns A number representing the trend.
 */
export const trend = (previousValue: number, currentValue: number): number => {
  if (previousValue === 0 && currentValue > 0) return RAISE_100_PERCENT;
  if (previousValue === 0 && currentValue < 0) return FALL_100_PERCENT;

  if (currentValue === 0 && previousValue > 0) return FALL_100_PERCENT;
  if (currentValue === 0 && previousValue < 0) return RAISE_100_PERCENT;

  if (previousValue === 0 && currentValue === 0) return NO_CHANGE;

  const delta = currentValue - previousValue;
  return delta / Math.abs(previousValue);
};