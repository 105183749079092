export const isValidInt32 = (value: number): boolean => {
  const MIN_32_BIT_INTEGER = -2147483648; // -2^31
  const MAX_32_BIT_INTEGER = 2147483647; // 2^31 - 1
  return value >= MIN_32_BIT_INTEGER && value <= MAX_32_BIT_INTEGER;
};

/**
 * It converts a signed 32-bit integer (in two's complement) to an unsigned 32-bit integer.
 * However, there's no actual data type conversion because Number in JavaScript is a 64-bit floating-point. The
 * conversion happens in binary representation.
 *
 * Example:
 * Raw binary: 1100 1100 1100 1100 1100 1100 1100 1100
 * IoT converts it to decimal based on two's complement, i.e., the most significant bit is interpreted as a sign.
 * The result decimal is -858993460
 * However, when converting decimal to binary, using JavaScript's toString(2), the string returned is the positive
 * binary representation of the number value preceded by a "-"" sign, not the two's complement of the number value.
 * i.e., Number(-858993460).toString(2) returns "11 0011 0011 0011 0011 0011 0011 0100", which is incorrect.
 *
 * Therefore, we need to convert IoT's decimal to an unsigned 32-bit integer first, by using unsigned right shift.
 * The result decimal is 3435973836.
 * After converting to binary, it is 1100 1100 1100 1100 1100 1100 1100 1100.
 * @param value a decimal number converted from a signed binary in two's complement.
 * @returns a decimal number in unsigned 32-bit integer
 */
function convertInt32ToUInt32(value) {
  return value >>> 0;
}

/**
 * It converts a decimal to binary. Negative numbers are converted to their unsigned 32-bit integer representation.
 * @param decimal a decimal number converted from a signed binary in two's complement.
 * @returns a binary string
 */
export const convertDecimalToBinary: (decimal: number) => string = (decimal) => {
  const unsignedInt32 = decimal < 0 ? convertInt32ToUInt32(decimal) : decimal;
  return unsignedInt32.toString(2).padStart(32, '0');
};