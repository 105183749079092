enum ActionTypes {
  LOAD_SAMPLE_POINT_SAMPLES_REQUEST = 'LOAD_SAMPLE_POINT_SAMPLES_REQUEST',
  LOAD_SAMPLE_POINT_SAMPLES_SUCCESS = 'LOAD_SAMPLE_POINT_SAMPLES_SUCCESS',
  LOAD_SAMPLE_POINT_SAMPLES_FAILURE = 'LOAD_SAMPLE_POINT_SAMPLES_FAILURE',
  LOAD_INTERVAL_SAMPLES_REQUEST = 'LOAD_INTERVAL_SAMPLES_REQUEST',
  LOAD_INTERVAL_SAMPLES_SUCCESS = 'LOAD_INTERVAL_SAMPLES_SUCCESS',
  LOAD_INTERVAL_SAMPLES_FAILURE = 'LOAD_INTERVAL_SAMPLES_FAILURE',
  LOAD_MACHINE_CONTROL_SAMPLES_REQUEST = 'LOAD_MACHINE_CONTROL_SAMPLES_REQUEST',
  LOAD_MACHINE_CONTROL_SAMPLES_SUCCESS = 'LOAD_MACHINE_CONTROL_SAMPLES_SUCCESS',
  LOAD_MACHINE_CONTROL_SAMPLES_FAILURE = 'LOAD_MACHINE_CONTROL_SAMPLES_FAILURE',
  LOAD_INTERNAL_SAMPLES_REQUEST = 'LOAD_INTERNAL_SAMPLES_REQUEST',
  LOAD_INTERNAL_SAMPLES_SUCCESS = 'LOAD_INTERNAL_SAMPLES_SUCCESS',
  LOAD_INTERNAL_SAMPLES_FAILURE = 'LOAD_INTERNAL_SAMPLES_FAILURE',
  LOAD_COMPARATIVE_SAMPLES_REQUEST = 'LOAD_COMPARATIVE_SAMPLES_REQUEST',
  LOAD_COMPARATIVE_SAMPLES_SUCCESS = 'LOAD_COMPARATIVE_SAMPLES_SUCCESS',
  LOAD_COMPARATIVE_SAMPLES_FAILURE = 'LOAD_COMPARATIVE_SAMPLES_FAILURE',
  SET_SAMPLE_POINT_SAMPLES = 'SET_SAMPLE_POINT_SAMPLES',
  SET_CONTROL_POINT_SAMPLES = 'SET_CONTROL_POINT_SAMPLES',
  SET_POWER_METER_SAMPLES = 'SET_POWER_METER_SAMPLES'
}

export default ActionTypes;
