export enum PowerMeterChannel {
  LINE_1_VOLTAGE = 3,
  LINE_1_CURRENT = 4,
  LINE_2_VOLTAGE = 5,
  LINE_2_CURRENT = 6,
  LINE_3_VOLTAGE = 7,
  LINE_3_CURRENT = 8,
  ENERGY = 2,
  FLAGS = 9
}

export enum PowerMeterStatus {
  OFFLINE = 0,
  ONLINE = 1
}

export type PowerLine = {
  lineNumber: 1 | 2 | 3;
  current: number | null;
  voltage: number | null;
};
