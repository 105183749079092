import IPCamera from 'types/models/ip-camera';
import SamplePoint from 'types/models/samplePoint';
import Site from 'types/models/site';
import Trigger, { TriggerId } from 'types/models/trigger';
import { TriggerMessage } from 'types/trigger.enum';

export type EventId = number;

export enum Level {
  INFO = 1,
  WARNING = 2,
  ERROR = 3,
  CRITICAL = 4,
  SOS = 5
}

export type EventSite = Pick<Site, 'id' | 'enterpriseId' | 'timezoneCode'>;

export type EventSamplePoint = Pick<
  SamplePoint,
  'id' | 'sid' | 'name' | 'siteId' | 'deviceId'
> & {
  site: EventSite
};

export type EventIPCamera = Pick<
  IPCamera,
  'id' | 'name' | 'siteId' | 'landwatchIntegrationId' | 'name' | 'description'
> & {
  site: EventSite
};

export interface BaseEvent {
  id: EventId;
  level: Level;
  message: TriggerMessage;
  date: number;
}

/**
 * sample-point-event (from NotificationEventType)
 * - will always have an Event
 * = will always have a Sample Point
 * - will have a Trigger IF the cause was an automated Sample Point alert
 */
export interface SamplePointEvent extends BaseEvent {
  samplePoint: EventSamplePoint;
  trigger?: Trigger | null;
  triggerId?: TriggerId | null;
  triggerValue?: number;
}
/**
 * ip-camera-event (from NotificationEventType)
 * - will always have an Event
 * - will always have an IP Camera
 */
export interface IPCameraEvent extends Omit<BaseEvent, 'message'> {
  ipCamera: EventIPCamera;
  message: 'SmartHumanMotion' | 'SmartVehicleMotion';
}