import { type BitGroup } from './bit';

type CurrentOrVoltageLinePresence = {
  isExpectedPresent: boolean;
  isActuallyPresent: boolean;
};

export const checkLinePresence = (bitGroup: BitGroup): CurrentOrVoltageLinePresence => {
  const isExpectedPresent = bitGroup[0] === '1';
  const isActuallyPresent = bitGroup[1] === '1';
  return { isExpectedPresent, isActuallyPresent };
};