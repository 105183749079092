import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip, Typography } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import React, { CSSProperties, memo } from 'react';

import './styles.less';

type LearnMoreTooltipProps = {
  children: string | JSX.Element;
  infoIconStyle?: CSSProperties;
  link?: string;
  placement?: TooltipPlacement
  showInfoIcon?: boolean;
  title: string;
};

const LearnMoreTooltip = ({
  children,
  infoIconStyle,
  link,
  placement,
  showInfoIcon = true,
  title
}: LearnMoreTooltipProps) => {
  if (!title && !link) return <>{children}</>;

  return (
    <Tooltip
      title={
        <>
          {title}{' '}
          {link && <Typography.Link
            href={link}
            target="_blank"
            className="LearnMoreTooltip-link"
          >
            Learn More
          </Typography.Link>}
        </>
      }
      placement={placement}
    >
      {children}
      {showInfoIcon && <InfoCircleOutlined className="LearnMoreTooltip-icon" style={infoIconStyle} />}
    </Tooltip>
  );
};

export default memo(LearnMoreTooltip);
