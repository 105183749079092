/**
 * Request information about the selected enterprise that doesn't change very
 * often.
 */
import axios from 'axios';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import { SagaIterator } from 'redux-saga';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { setDevices } from 'store/modules/devices/actions';
import { setSamplePoints } from 'store/modules/samplePoints/actions';
import { loadSamplePointsStatistics } from 'store/modules/samplePointsStatistics/actions';
import { setSites } from 'store/modules/sites/actions';
import { setTriggers } from 'store/modules/triggers/actions';
import { setUsers } from 'store/modules/users/actions';
import SamplePoint, { RawSamplePoint, SamplePointId } from 'types/models/samplePoint';
import Site from 'types/models/site';
import User from 'types/models/user';
import { parseRawSamplePoints } from 'utils/associated-sample-points/parse-raw-samplepoints';
import parseBackendPhoneNumber from 'utils/phone-number/parse-backend-phone-number';
import { getRequest } from 'utils/redux-saga-requests';

import getMockMachineControls from './__tests__/fixtures/getMockMachineControls';
import {
  loadEnterpriseScaffold,
  loadEnterpriseScaffoldFailure,
  loadEnterpriseScaffoldSuccess
} from './actions';
import ActionTypes from './constants';
import { selectCurrentEnterpriseCountry } from '../enterprise/selectors';
import { selectDefaultSite } from '../localUserSettings/selectors';
import { loadRecentNotifications } from '../recentNotifications/actions';
import { parseSites } from '../sites/sagaHelper';
import trackEvent from '../tracking/actions';
import { EventType } from '../tracking/types';

function convertUnits(
  samplePoints: Record<SamplePointId, SamplePoint>
): Record<SamplePointId, SamplePoint> {
  return mapValues(samplePoints, (sp) => {
    const samplePoint = { ...sp };
    if (samplePoint.config?.maxValue) {
      samplePoint.config.maxValue = +samplePoint.config.maxValue;
    }
    if (samplePoint.config?.totalCapacity) {
      samplePoint.config.totalCapacity = +samplePoint.config.totalCapacity;
    }

    return samplePoint;
  });
}

export function* requestEnterpriseScaffold(
  action: ReturnType<typeof loadEnterpriseScaffold>
): SagaIterator {
  const {
    payload: { enterpriseId, isInitialLoading, onInitialLoadingSuccess }
  } = action;

  try {
    const { data } = yield call(getRequest, `enterprise/${enterpriseId}/core`);
    const { devices, sites, triggers, users, enterprise } = data;
    let samplePoints: RawSamplePoint[] = data.samplepoints;
    // Testing only. It works with mocks in 1) store/modules/samplePointsStatistics/saga.ts
    // 2) components/features/machineControl/MachineControlPanel/index.tsx
    let machineControlTestingEnterpriseId = 779;
    let machineControlTestingSiteId = 722;
    let machineControlTestingDeviceId = 32694;
    if (process.env.REACT_APP_ENV === 'staging') {
      machineControlTestingEnterpriseId = 1755;
      machineControlTestingSiteId = 4330;
      machineControlTestingDeviceId = 30281;
    }
    if (
      process.env.REACT_APP_ENV !== 'production' &&
      enterprise.id === machineControlTestingEnterpriseId
    ) {
      samplePoints = samplePoints.concat(
        // @ts-ignore TODO: the return type of getMockMachineControls is not compatible with RawSamplePoint[]
        getMockMachineControls(
          machineControlTestingDeviceId,
          machineControlTestingSiteId
        )
      );
    }
    const country = yield select(selectCurrentEnterpriseCountry);
    const parsedSamplePoints = parseRawSamplePoints(samplePoints, country);
    let samplePointsDictionary = keyBy(parsedSamplePoints, 'id');
    samplePointsDictionary = convertUnits(samplePointsDictionary);

    const devicesData = keyBy(devices, 'id');
    const triggersData = keyBy(triggers, 'id');
    const parsedSites = parseSites(sites, country);
    const sitesDict = keyBy(parsedSites, 'id');
    const usersData = keyBy(
      users.map((user: User): User => {
        const newUser: User = parseBackendPhoneNumber(
          user,
          'mobileNumber',
          'mobileNumberCountryCode',
          'mobileNumberFormatted'
        );
        return parseBackendPhoneNumber(
          newUser,
          'landlineNumber',
          'landlineNumberCountryCode',
          'landlineNumberFormatted'
        );
      }),
      'id'
    );

    yield all([
      put(setSites(sitesDict)),
      put(setDevices(devicesData)),
      put(setSamplePoints(samplePointsDictionary)),
      put(setTriggers(triggersData)),
      put(setUsers(usersData)),
      put(loadEnterpriseScaffoldSuccess())
    ]);
    yield put(loadRecentNotifications(enterprise.id, isInitialLoading));
    /**
     * FMBT-978 To make sure the sample points and their statuses are synced.
     * ref: https://farmbot.atlassian.net/browse/FMBT-978
     */
    yield put(loadSamplePointsStatistics(enterpriseId));
    if (isInitialLoading) {
      const sensorTypes = parsedSamplePoints.reduce(
        (acc: number[], sp: SamplePoint) => {
          if (!acc.includes(sp.assetTypeId)) {
            acc.push(sp.assetTypeId);
          }
          return acc;
        },
        []
      );
      onInitialLoadingSuccess?.({
        kind: 'enterprise',
        key: enterprise.id,
        name: enterprise.name,
        industry: enterprise.industry,
        sensorTypes,
        numberOfDevices: devices.length,
        numberOfSites: sites.length,
        numberOfSensors: samplePoints.length,
        country,
        createdAt: enterprise.createdAt
      });
      yield put(
        trackEvent({
          type: EventType.SET_ENTERPRISE,
          data: {
            groupType: 'company',
            groupValue: enterprise.name,
            name: enterprise.name,
            industry: enterprise.industry,
            numberOfSites: sites.length,
            numberOfSensors: samplePoints.length,
            createdAt: new Date(enterprise.createdAt).toISOString()
          }
        })
      );
      const currentSite: Site = yield select(selectDefaultSite);
      yield put(
        trackEvent({
          type: EventType.SET_SITE,
          data: {
            groupType: 'site',
            groupValue: `${enterprise?.name} - ${currentSite.name}`,
            name: currentSite.name,
            id: currentSite.sid,
            numberOfSensors: parsedSamplePoints.filter(
              (sp: SamplePoint) => sp.siteId === currentSite.id
            )?.length,
            siteSize: currentSite.siteSize
          }
        })
      );
    }
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    yield put(loadEnterpriseScaffoldFailure(message, error));
  }
}

export function* watchLoadEnterpriseScaffoldRequest() {
  yield takeLatest(
    ActionTypes.LOAD_ENTERPRISE_SCAFFOLD_REQUEST,
    requestEnterpriseScaffold
  );
}

export default function* EnterpriseScaffoldSaga() {
  yield all([fork(watchLoadEnterpriseScaffoldRequest)]);
}
