import rollbarLogger from 'config/rollbar';
import { AssetTypeCode } from 'types/models/asset-type';
import SamplePoint, { MergedSamplePoint, SamplePointId } from 'types/models/samplePoint';
import { AssociatedPowerMeterSamplePoints } from 'utils/associated-sample-points/merge-power-meters';
import { assertIsDefined } from 'utils/TypeScript/assert-is-defined';

/** NOTE: This util will throw if it cannot guarantee the shape of its returned object */
export const getOriginalSamplePointIdsFromMergedPowerMeter = (
  samplePoint: MergedSamplePoint<AssociatedPowerMeterSamplePoints>
): {
  flagsSamplePointId: SamplePointId;
  energySamplePointId: SamplePointId;
  line1VoltageSamplePointId: SamplePointId;
  line1CurrentSamplePointId: SamplePointId;
  line2VoltageSamplePointId?: SamplePointId;
  line2CurrentSamplePointId?: SamplePointId;
  line3VoltageSamplePointId?: SamplePointId;
  line3CurrentSamplePointId?: SamplePointId;
} => {
  assertIsDefined<SamplePoint>(samplePoint._hidden);
  assertIsDefined<SamplePoint>(samplePoint._hidden.energy);
  assertIsDefined<SamplePoint>(samplePoint._hidden.line1Voltage);
  assertIsDefined<SamplePoint>(samplePoint._hidden.line1Current);

  return {
    flagsSamplePointId: samplePoint.id,
    energySamplePointId: samplePoint._hidden.energy.id,
    line1VoltageSamplePointId: samplePoint._hidden.line1Voltage.id,
    line1CurrentSamplePointId: samplePoint._hidden.line1Current.id,
    line2VoltageSamplePointId: samplePoint._hidden.line2Voltage?.id,
    line2CurrentSamplePointId: samplePoint._hidden.line2Current?.id,
    line3VoltageSamplePointId: samplePoint._hidden.line3Voltage?.id,
    line3CurrentSamplePointId: samplePoint._hidden.line3Current?.id
  };
};

/**
 * Get sample point id(s) from a merged sample point
 * @summary Most of the sample point only has one id, while like soil sample
 * point has two ids.
 * @param samplePoint - Sample point
 * @returns sample point id array
 */
export default function getOriginalSamplePointIdsFromMergedSamplePoint(
  samplePoint: MergedSamplePoint
): SamplePointId[] {
  switch (samplePoint.assetTypeId) {
    case AssetTypeCode.SOIL:
    case AssetTypeCode.SAFETY_CHECK_IN:
      try {
        assertIsDefined(samplePoint._hidden);
        return [samplePoint.id, samplePoint._hidden.id];
      } catch (error) {
        if (samplePoint.assetTypeId === AssetTypeCode.SAFETY_CHECK_IN) {
          rollbarLogger.warning(`Paired SOS sensor not found. Check-in sensor id: ${samplePoint.id}`, samplePoint);
        }
        return [samplePoint.id];
      }
    case AssetTypeCode.POWER_METER: {
      try {
        const idMap = getOriginalSamplePointIdsFromMergedPowerMeter(
          samplePoint as MergedSamplePoint<AssociatedPowerMeterSamplePoints>
        );
        return Object.values(idMap).filter(Boolean);
      } catch (error) {
        rollbarLogger.warning('Associated power meter sample points not found for flags sample point id', { id: samplePoint.id });
        return [samplePoint.id];
      }
    }
    default:
      return [samplePoint.id];
  }
}
