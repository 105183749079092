import { AssetTypeCode } from 'types/models/asset-type';
import { BaseEvent, IPCameraEvent } from 'types/models/event';
import { SamplePointId } from 'types/models/samplePoint';
import { SiteId } from 'types/models/site';

export enum EventType {
  LOG_OUT = 'User Logged Out',
  NOTIFICATION_ACKNOWLEDGED = 'Notification Acknowledged',
  THIRD_PARTY_NOTIFICATION_ACKNOWLEDGED = 'Third Party Notification Acknowledged',
  SITE_CREATED = 'Site Created',
  SITE_REMOVED = 'Site Removed',
  SENSOR_SET_UP = 'Sensor Set Up',
  SENSOR_EXPORTED = 'Sensor Exported',
  SENSOR_PRINTED = 'Sensor Printed',
  SENSOR_VIEWED = 'Sensor Viewed',
  SENSOR_ACTION_REQUESTED = 'Sensor Action Requested',
  SENSOR_ACTION_AUTOMATED = 'Sensor Action Automated',
  SENSOR_SETTINGS_CLICKED = 'Sensor Settings Clicked',
  SET_ENTERPRISE = 'Set Enterprise',
  SET_SITE = 'Set Site',
  SET_USER = 'Set User',
  USER_INVITED = 'User Invited',
  USER_SIGNED_UP = 'User Signed Up',
  USER_JOINED_ENTERPRISE = 'User Joined Enterprise',
  USER_REMOVED = 'User Removed'
}

interface CommonData {
  /** The action tracked is successful or not */
  status?: boolean;
}

interface ButtonData extends CommonData {
  buttonLocation: string;
}

export interface AssetTypeData extends CommonData {
  assetTypeId: AssetTypeCode;
}

export interface InvitationData extends CommonData {
  inviterId?: string;
  inviterEmail?: string;
  inviteeId: string;
  inviteeEmail: string;
  inviteeRole: string;
  inviteeIsEnterpriseOwner: boolean;
  companyId: string;
  company: string;
  country?: string;
  entryPoint: string;
}

export interface NewUserData extends CommonData {
  userId?: string;
  email: string;
  isEnterpriseOwner: boolean;
  entryPoint?: string;
}

export interface UserJoinEnterpriseData extends NewUserData {
  companyId: string;
  company: string;
}

export interface SamplePointData extends AssetTypeData {
  samplePointId: SamplePointId;
}

export interface ThirdPartyAssetTypeData extends AssetTypeData {
  entityId: string;
  entityType: string;
}

export interface SensorViewedData extends SamplePointData {
  entryPoint: string;
  sensorLocation?: string;
  action?: string;
  actionAttributes?: string;
}

export interface SensorActionRequestedData extends SensorViewedData {
  buttonName: string;
  settingsTab?: string;
}

export interface GroupData extends CommonData {
  /** The name of the user property in Amplitude */
  groupType: string;
  /** The value of the user property in Amplitude */
  groupValue: string;
  name: string;
}

export interface GroupEnterpriseData extends GroupData {
  industry: string;
  numberOfSites: number;
  numberOfSensors: number;
  createdAt: string;
}

export interface GroupSiteData extends GroupData {
  id: string;
  numberOfSensors: number;
  siteSize?: number;
}

interface TrackingEventObject<
  T extends EventType,
  D extends CommonData = CommonData
> {
  type: T;
  data: D;
}

export type TrackingEvent =
  | TrackingEventObject<EventType.LOG_OUT, ButtonData>
  | TrackingEventObject<EventType.SET_ENTERPRISE, GroupEnterpriseData>
  | TrackingEventObject<EventType.SET_SITE, GroupSiteData>
  | TrackingEventObject<EventType.SET_USER>
  | TrackingEventObject<EventType.SENSOR_PRINTED, AssetTypeData>
  | TrackingEventObject<EventType.USER_INVITED, InvitationData>
  | TrackingEventObject<EventType.USER_SIGNED_UP, NewUserData>
  | TrackingEventObject<
    EventType.USER_JOINED_ENTERPRISE,
    UserJoinEnterpriseData
  >
  | TrackingEventObject<EventType.USER_REMOVED>
  | TrackingEventObject<
    EventType.SITE_CREATED,
    CommonData & { siteId?: SiteId }
  >
  | TrackingEventObject<EventType.SITE_REMOVED, CommonData & { siteId: SiteId }>
  | TrackingEventObject<EventType.SENSOR_SET_UP, SamplePointData>
  | TrackingEventObject<EventType.SENSOR_EXPORTED, SamplePointData>
  | TrackingEventObject<EventType.SENSOR_VIEWED, SensorViewedData>
  | TrackingEventObject<
    EventType.SENSOR_ACTION_REQUESTED,
    SensorActionRequestedData
  >
  | TrackingEventObject<
    EventType.SENSOR_ACTION_AUTOMATED,
    SensorActionRequestedData
  >
  | TrackingEventObject<
    EventType.SENSOR_SETTINGS_CLICKED,
    SensorActionRequestedData
  >
  | TrackingEventObject<
    EventType.NOTIFICATION_ACKNOWLEDGED,
    SamplePointData & {
      eventLevel: BaseEvent['level'];
      eventMessage: BaseEvent['message'];
    }
  >
  | TrackingEventObject<
    EventType.THIRD_PARTY_NOTIFICATION_ACKNOWLEDGED,
    ThirdPartyAssetTypeData & {
      eventLevel: BaseEvent['level'];
      eventMessage: IPCameraEvent['message']; // Feel free to add here other kinds of third party events in the future
    }
  >;
