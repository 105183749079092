import { PowerMeterChartAssetType } from 'components/features/samplePoint/PowerMeterSampleChart';
import { AdvancedDamChartType, FlowChartType, SimpleDamChartType, TankChartType, TroughDirectChartType } from 'components/features/samplePoint/SamplePointSampleChart/sampleChartType.enum';
import { CommonDateRangeKey, ExtendedDateRangeKey, SafetyCheckInDateRangeKey } from 'constants/chart-date-range';
import { AssetTypeCode } from 'types/models/asset-type';
import SamplePoint from 'types/models/samplePoint';
import { SiteId } from 'types/models/site';

export enum ComparisonMode {
  BY_TIME_PERIODS,
  BY_SAMPLE_POINTS
}

export enum ComparisonPeriod {
  DAY_30 = 'day.30',
  DAY_30_LAST_YEAR = 'day.30.last-year',
  WEEK_13 = 'week.13',
  MONTH_12 = 'month.12'
}

export interface ComparisonSettings {
  mode: ComparisonMode,
  period?: ComparisonPeriod
  samplePointSids?: SamplePoint['sid'][]
}

export interface LocalUserSettingsState {
  waterLevelChartType: TankChartType | null;
  fuelLevelChartType: TankChartType | null;
  liquidFertiliserLevelChartType: TankChartType | null;
  flowChartType: FlowChartType | null;
  simpleDamChartType: SimpleDamChartType | null;
  advancedDamChartType: AdvancedDamChartType | null;
  troughDirectChartType: TroughDirectChartType | null;
  // pressureChartType: PressureChartType | null;
  defaultChartDateRanges: Record<AssetTypeCode,
    | CommonDateRangeKey
    | ExtendedDateRangeKey
    | SafetyCheckInDateRangeKey
  >
  showChartList: boolean;
  showChartListForPowerMeter?: Record<PowerMeterChartAssetType, boolean>;
  comparisonSettings: ComparisonSettings | null;
  /**
   * The default site when loading the app.
   * It's the current selected site.
   */
  defaultSiteId?: SiteId;
}
