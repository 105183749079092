import { List, Space, Typography, message as antdMessage } from 'antd';
import React, { memo, useEffect } from 'react';
import { FiExternalLink } from 'react-icons/fi';
import { useDispatch } from 'react-redux';

import IconLabel from 'components/atoms/IconLabel';
import DrawerSimpleMap from 'components/composites/DrawerSimpleMap';
import LoadingSpinner from 'components/composites/LoadingSpinner';
import { EM_DASH } from 'constants/index';
import useAssetType from 'store/modules/assetTypes/hooks';
import { useCountry } from 'store/modules/countries/hooks';
import { useEnterpriseCountry } from 'store/modules/enterprise/hooks';
import { ephemeralGetRequest } from 'store/modules/ephemeralRequest/actions';
import { AssetTypeCode } from 'types/models/asset-type';
import { BackOfficeDeviceMapDevicePointDetails } from 'types/models/back-office-device-map-point';
import { DeviceId } from 'types/models/device';
import constructEnterpriseDomain from 'utils/construct-enterprise-domain';
import { RAW_TO_PARSED_ASSET_TYPE_CODE } from 'utils/SamplePoints/asset-types/parse-raw-asset-types';

interface ListItem {
  label: string | JSX.Element;
  content: string | JSX.Element;
  copyable: boolean;
}

interface BackOfficeDeviceMapDeviceDetailsProps {
  deviceId: DeviceId;
}

function BackOfficeDeviceMapDeviceDetails({ deviceId }: BackOfficeDeviceMapDeviceDetailsProps) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(true);
  const [devicePoint, setDevicePoint] = React.useState<BackOfficeDeviceMapDevicePointDetails>();

  const country = useEnterpriseCountry();
  const countryObj = useCountry(country);

  useEffect(() => {
    (async () => {
      try {
        const devicePointData = await new Promise<BackOfficeDeviceMapDevicePointDetails>((resolve, reject) => {
          setIsLoading(true);
          dispatch(
            ephemeralGetRequest<BackOfficeDeviceMapDevicePointDetails>(
              `device-map/points/${deviceId}`,
              resolve,
              reject
            )
          );
        });

        setDevicePoint(devicePointData);
      } catch (error) {
        antdMessage.error(
          `Error loading device detail: ${error instanceof Error ? error.message : error}`
        );
      } finally {
        setIsLoading(false);
      }
    })();
  }, [deviceId, dispatch]);

  if (isLoading) return <LoadingSpinner />;
  if (!devicePoint || !countryObj) return <></>;

  const handleRenderItem = ({ label, content, copyable }: ListItem) => {
    return (
      <List.Item>
        <Space direction="vertical" className="w-full">
          <Typography.Text strong>{label}</Typography.Text>
          <Typography.Text copyable={copyable}>
            {content}
          </Typography.Text>
        </Space>
      </List.Item>
    );
  };

  const renderEnterpriseContent = () => {
    const enterpriseLink = constructEnterpriseDomain({ subdomain: devicePoint.enterpriseSubdomain });
    const enterpriseName = devicePoint.enterpriseName || EM_DASH;

    return (
      <Typography.Link href={enterpriseLink} target="_blank">
        <IconLabel
          icon={<FiExternalLink size="22" />}
          label={enterpriseName}
          gap={11}
        />
      </Typography.Link>
    );
  };

  const renderOwnerContent = () => {
    const ownerName = devicePoint.ownerName || EM_DASH;
    const ownerEmail = devicePoint.ownerEmail || EM_DASH;
    const ownerPhone = devicePoint.ownerPhone || EM_DASH;
    return (
      <>
        <Typography.Paragraph>
          <Typography.Text strong>Name: </Typography.Text>
          {ownerName}
        </Typography.Paragraph>
        <Typography.Paragraph>
          <Typography.Text strong>Email: </Typography.Text>
          <Typography.Link href={ownerEmail} copyable={!!ownerEmail}>{ownerEmail}</Typography.Link>
        </Typography.Paragraph>
        <Typography.Paragraph>
          <Typography.Text strong>Phone: </Typography.Text>
          <Typography.Text copyable={!!ownerPhone}>{ownerPhone}</Typography.Text>
        </Typography.Paragraph>
      </>
    );
  };

  const AssetCount = ({ assetTypeId, count }: { assetTypeId: AssetTypeCode, count: number }) => {
    const assetName = useAssetType(assetTypeId).name;
    return (
      <Typography.Paragraph>
        <Typography.Text strong>{assetName}: </Typography.Text>
        {count}
      </Typography.Paragraph>
    );
  };

  const renderSensorCountContent = () => {
    const assetCounts = devicePoint.assetCounts || [];

    if (assetCounts.length === 0) {
      return 'No sensors connected';
    }

    return (
      <>{
        assetCounts
          .map((asset, index) => {
            const { assetTypeId, count } = asset;
            const supportedAssetTypeId = RAW_TO_PARSED_ASSET_TYPE_CODE[assetTypeId];
            if (!supportedAssetTypeId) return null;

            return (
              <AssetCount
                key={index}
                assetTypeId={supportedAssetTypeId}
                count={count}
              />
            );
          })
          .filter(Boolean)
      }</>
    );
  };

  const renderDeviceLocationContent = () => {
    if (!devicePoint.lat || !devicePoint.lng) return EM_DASH;

    return [devicePoint.lat.toFixed(5), devicePoint.lng.toFixed(5)].join(', ');
  };

  const contentItems: ListItem[] = [
    {
      label: 'Serial Number',
      content: devicePoint.serialNumber || EM_DASH,
      copyable: true
    },
    {
      label: 'Name',
      content: devicePoint.name || EM_DASH,
      copyable: false
    },
    {
      label: 'Device Location',
      content: renderDeviceLocationContent(),
      copyable: true
    },
    {
      label: 'Enterprise',
      content: renderEnterpriseContent(),
      copyable: false
    },
    {
      label: 'Owner',
      content: renderOwnerContent(),
      copyable: false
    },
    {
      label: 'Sensor Count',
      content: renderSensorCountContent(),
      copyable: false
    }
  ];

  return (
    <>
      <List
        dataSource={contentItems}
        renderItem={handleRenderItem}
      />
      <DrawerSimpleMap
        location={[devicePoint.lat, devicePoint.lng]}
        country={countryObj}
      />
    </>
  );
}

export default memo(BackOfficeDeviceMapDeviceDetails);