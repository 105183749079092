import Sample from 'types/models/sample';
import { checkLinePresence, parsePowerMeterFlagsSample } from 'utils/Sample/power-meter';

export const checkPowerMeterSupportedLines = (flagsSampleValue: Sample['rwValue']) => {
  const { l1c, l1v, l2c, l2v, l3c, l3v } = parsePowerMeterFlagsSample(flagsSampleValue) || {};
  const { isExpectedPresent: isL1CExpected } = checkLinePresence(l1c || '0000');
  const { isExpectedPresent: isL1VExpected } = checkLinePresence(l1v || '0000');
  const { isExpectedPresent: isL2CExpected } = checkLinePresence(l2c || '0000');
  const { isExpectedPresent: isL2VExpected } = checkLinePresence(l2v || '0000');
  const { isExpectedPresent: isL3CExpected } = checkLinePresence(l3c || '0000');
  const { isExpectedPresent: isL3VExpected } = checkLinePresence(l3v || '0000');

  const isLine1Supported: boolean = isL1CExpected && isL1VExpected;
  const isLine2Supported: boolean = isL2CExpected && isL2VExpected;
  const isLine3Supported: boolean = isL3CExpected && isL3VExpected;

  return {
    isLine1Supported,
    isLine2Supported,
    isLine3Supported
  };
};