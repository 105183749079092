import { MergedSamplePoint, SamplePointId } from 'types/models/samplePoint';
import SamplePointStatistic, { PowerMeterStatistic } from 'types/models/samplePointsStatistic';
import { getOriginalSamplePointIdsFromMergedPowerMeter } from 'utils/associated-sample-points/get-original-sample-point-ids-from-merged-sample-point';
import { AssociatedPowerMeterSamplePoints } from 'utils/associated-sample-points/merge-power-meters';
import { assertIsPowerMeterFlagsStatistic } from 'utils/SamplePointStatistics/assert-is-power-meter-flags-statistic';
import { assertIsSamplePointStatisticWithAggregates } from 'utils/SamplePointStatistics/assert-is-with-aggregates';

/** Returns null if cannot guarantee a PowerMeterStatistic output */
export function getMergedStatisticForPowerMeter(
  allStatistics: Record<number, SamplePointStatistic>,
  mainSamplePoint: MergedSamplePoint<AssociatedPowerMeterSamplePoints>
): PowerMeterStatistic | null {
  let flagsSamplePointId: SamplePointId;
  let energySamplePointId: SamplePointId;
  let line1VoltageSamplePointId: SamplePointId;
  let line1CurrentSamplePointId: SamplePointId;
  let line2VoltageSamplePointId: SamplePointId | undefined;
  let line2CurrentSamplePointId: SamplePointId | undefined;
  let line3VoltageSamplePointId: SamplePointId | undefined;
  let line3CurrentSamplePointId: SamplePointId | undefined;

  let flagsStatistic, energyStatistic;

  try {
    ({
      flagsSamplePointId,
      energySamplePointId,
      line1VoltageSamplePointId,
      line1CurrentSamplePointId,
      line2VoltageSamplePointId,
      line2CurrentSamplePointId,
      line3VoltageSamplePointId,
      line3CurrentSamplePointId
    } = getOriginalSamplePointIdsFromMergedPowerMeter(mainSamplePoint));

    flagsStatistic = allStatistics[flagsSamplePointId];
    assertIsPowerMeterFlagsStatistic(flagsStatistic);

    energyStatistic = allStatistics[energySamplePointId];
    assertIsSamplePointStatisticWithAggregates(energyStatistic);
  } catch (error) {
    // Shape of inputs is not as expected, so we cannot produce a proper PowerMeterStatistic output
    return null;
  }

  const result: PowerMeterStatistic = {
    ...flagsStatistic,
    energy: energyStatistic,
    line1Voltage: allStatistics[line1VoltageSamplePointId],
    line1Current: allStatistics[line1CurrentSamplePointId],
    // A power meter monitoring a single line will not have sample points for voltage and current for lines 2 and 3
    line2Voltage: line2VoltageSamplePointId ? allStatistics[line2VoltageSamplePointId] : null,
    line2Current: line2CurrentSamplePointId ? allStatistics[line2CurrentSamplePointId] : null,
    line3Voltage: line3VoltageSamplePointId ? allStatistics[line3VoltageSamplePointId] : null,
    line3Current: line3CurrentSamplePointId ? allStatistics[line3CurrentSamplePointId] : null
  };
  return result;
}