import isNil from 'lodash/isNil';

import { PowerLine, PowerMeterStatus } from 'types/models/power-meter';
import Sample from 'types/models/sample';
import SamplePoint from 'types/models/samplePoint';
import { PowerMeterStatistic } from 'types/models/samplePointsStatistic';
import { trend } from 'utils/Math/trend';
import {
  getCurrentValueAgainstFlags,
  getMeterStatusAgainstFlags,
  getVoltageValueAgainstFlags
} from 'utils/Sample/power-meter';
import { checkPowerMeterSupportedLines } from 'utils/SamplePoints/power-meter/check-supported-lines';

// ==============================
// TYPES
// ==============================
type LinesSummary = {
  line1: number | null;
  line2: number | null;
  line3: number | null;
};

type CalculateCurrentOrVoltageSummaryFunction = (
  valueType: 'current' | 'voltage',
  lines: {
    line1Statistic: PowerMeterStatistic['line1Current'] | PowerMeterStatistic['line1Voltage'];
    line2Statistic: PowerMeterStatistic['line2Current'] | PowerMeterStatistic['line2Voltage'];
    line3Statistic: PowerMeterStatistic['line3Current'] | PowerMeterStatistic['line3Voltage'];
  },
  metadata: {
    flagsSampleValue: Sample['rwValue'] | undefined
  }
) => LinesSummary;

// ==============================
// HELPERS
// ==============================
const calculateCurrentOrVoltageSummary: CalculateCurrentOrVoltageSummaryFunction = (
  valueType: 'current' | 'voltage',
  { line1Statistic, line2Statistic, line3Statistic },
  { flagsSampleValue }
) => {
  if (isNil(flagsSampleValue)) { // Without the flags, we cannot determine the current sampleValues correctly.
    return {
      line1: null,
      line2: null,
      line3: null
    };
  }

  const checkValueAgainstFlags = valueType === 'current'
    ? getCurrentValueAgainstFlags
    : getVoltageValueAgainstFlags;

  const latestLine1Value: number | null = checkValueAgainstFlags(
    flagsSampleValue,
    1,
    line1Statistic.lastSample?.rwValue
  );

  const latestLine2Value: number | null = checkValueAgainstFlags(
    flagsSampleValue,
    2,
    line2Statistic?.lastSample?.rwValue
  );
  const latestLine3Value: number | null = checkValueAgainstFlags(
    flagsSampleValue,
    3,
    line3Statistic?.lastSample?.rwValue
  );

  return {
    line1: latestLine1Value,
    line2: latestLine2Value,
    line3: latestLine3Value
  };
};

// ==============================
// MAIN EXPORT
// ==============================
/**
 * Performs calculations and unit conversions on the raw statistics data from the store
 * @param rawStatistic data in the store that has been merged over multiple sub sample points but has not undergone any
 * calculations (like summing, averaging, etc.)
 * @returns statistics ready for at-a-glance display (e.g. Map View, Sensor List, Sensor Detail Summary Card etc.)
 */
export const computePowerMeterSummaryMetrics = (rawStatistic: PowerMeterStatistic): SamplePoint['powerStatistics'] => {
  const {
    lastSample,
    previousRwValue: previousFlagsSampleValue,
    energy
  } = rawStatistic;

  const flagsSampleValue: number | undefined = lastSample?.rwValue;
  const {
    isLine1Supported,
    isLine2Supported,
    isLine3Supported
  } = isNil(flagsSampleValue)
      ? {
        isLine1Supported: false,
        isLine2Supported: false,
        isLine3Supported: false
      } : checkPowerMeterSupportedLines(flagsSampleValue);

  // Calculate energy usage
  const energyToday: number | null = energy.aggregates.today?.values?.sum ?? null;
  const energyLast7Days: number | null = energy.aggregates.rolling7Days?.values?.sum ?? null;
  const energyLast14Days: number | null = energy.aggregates.rolling14Days?.values?.sum ?? null;
  const energyPrevious7Days: number | null = isNil(energyLast7Days) || isNil(energyLast14Days)
    ? null
    : energyLast14Days - energyLast7Days;
  const energyTrend7Days = isNil(energyPrevious7Days) || isNil(energyLast7Days)
    ? null
    : trend(energyPrevious7Days, energyLast7Days);

  const totalMeterReading: number | null = energy.aggregates.totalMeterReading?.values?.sum ?? null;

  // Calculate voltage summary
  const {
    line1: latestLine1Voltage,
    line2: latestLine2Voltage,
    line3: latestLine3Voltage
  }: LinesSummary = calculateCurrentOrVoltageSummary('voltage', {
    line1Statistic: rawStatistic.line1Voltage,
    line2Statistic: rawStatistic.line2Voltage,
    line3Statistic: rawStatistic.line3Voltage
  }, {
    flagsSampleValue
  });

  // Calculate current summary
  const {
    line1: latestLine1Current,
    line2: latestLine2Current,
    line3: latestLine3Current
  }: LinesSummary = calculateCurrentOrVoltageSummary('current', {
    line1Statistic: rawStatistic.line1Current,
    line2Statistic: rawStatistic.line2Current,
    line3Statistic: rawStatistic.line3Current
  }, {
    flagsSampleValue
  });

  // Calculate power meter status
  const packetHasVoltageAndCurrent: boolean =
    rawStatistic.line1Voltage.lastSample?.date === lastSample?.date
    && rawStatistic.line1Current.lastSample?.date === lastSample?.date;
  const powerMeterStatus: PowerMeterStatus = isNil(flagsSampleValue)
    ? PowerMeterStatus.OFFLINE
    : getMeterStatusAgainstFlags(
      flagsSampleValue,
      packetHasVoltageAndCurrent,
      previousFlagsSampleValue || 0
    );

  // Calculate lines summary
  const lines: PowerLine[] = [];
  if (isLine1Supported) {
    lines.push({
      lineNumber: 1,
      current: latestLine1Current,
      voltage: latestLine1Voltage
    });
  }
  if (isLine2Supported) {
    lines.push({
      lineNumber: 2,
      current: latestLine2Current,
      voltage: latestLine2Voltage
    });
  }
  if (isLine3Supported) {
    lines.push({
      lineNumber: 3,
      current: latestLine3Current,
      voltage: latestLine3Voltage
    });
  }

  return {
    energyRolling7Days: energyLast7Days,
    energyToday,
    energyTrend7Days,
    lines,
    powerMeterStatus,
    totalMeterReading
  };
};