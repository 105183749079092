import { PowerLine } from 'types/models/power-meter';
import Sample, { PowerMeterSample, PowerMeterSubSample, SampleDate } from 'types/models/sample';
import SamplePoint, { SamplePointId } from 'types/models/samplePoint';
import { getCurrentValueAgainstFlags, getVoltageValueAgainstFlags } from 'utils/Sample/power-meter';
import { assertIsDefined } from 'utils/TypeScript/assert-is-defined';

import { getOriginalSamplePointIdsFromMergedPowerMeter } from './get-original-sample-point-ids-from-merged-sample-point';

// ==============================
// INTERNAL HELPERS
// ==============================
type SampleWithKnownFlags = PowerMeterSample & { flags: PowerMeterSubSample };
function assertIsSampleWithKnownFlags(sample: PowerMeterSample): asserts sample is SampleWithKnownFlags {
  assertIsDefined(sample.flags);
}

const handleLineValue = (
  sample: SampleWithKnownFlags,
  key: keyof Pick<PowerMeterSample, 'line1Voltage' | 'line1Current' | 'line2Voltage' | 'line2Current' | 'line3Voltage' | 'line3Current'>
): void => {
  const getValueAgainstFlags = ['line1Voltage', 'line2Voltage', 'line3Voltage'].includes(key)
    ? getVoltageValueAgainstFlags
    : getCurrentValueAgainstFlags;
  const lineNumber: PowerLine['lineNumber'] = ['line1Voltage', 'line1Current'].includes(key)
    ? 1
    : ['line2Voltage', 'line2Current'].includes(key)
      ? 2
      : 3;
  const value: number | null = getValueAgainstFlags(sample.flags.rwValue, lineNumber, sample[key]?.rwValue);
  if (value === null) {
    delete sample[key];
  } else if (sample[key]) {
    sample[key]!.rwValue = value; // Assertion needed because TypeScript just can't infer despite the if
  } else {
    sample[key] = {
      rwValue: value,
      prevDate: sample.flags.prevDate,
      extraValues: { seqNo: null }
    };
  }
};

const handleLineValuesAgainstFlags = (sample: SampleWithKnownFlags): void => {
  if (sample.line1Current) handleLineValue(sample, 'line1Current');
  if (sample.line1Voltage) handleLineValue(sample, 'line1Voltage');
  if (sample.line2Current) handleLineValue(sample, 'line2Current');
  if (sample.line2Voltage) handleLineValue(sample, 'line2Voltage');
  if (sample.line3Current) handleLineValue(sample, 'line3Current');
  if (sample.line3Voltage) handleLineValue(sample, 'line3Voltage');
};

const searchForNearestPrecedingFlagsSample = (
  samplesByDateUnordered: Map<SampleDate, PowerMeterSample>,
  datesArrayNewestFirst: SampleDate[],
  currentDateIndex: number
): PowerMeterSubSample | undefined => {
  for (let i = currentDateIndex + 1; i < datesArrayNewestFirst.length; i++) {
    const sample = samplesByDateUnordered.get(datesArrayNewestFirst[i]);
    if (sample?.flags) {
      return sample.flags;
    }
  }
  return undefined;
};

// ==============================
// MAIN EXPORT
// ==============================
/**
 * Merges samples from multiple sub-sample points of a power meter into a single map.
 * The resulting map contains entries sorted by date in descending order (newest first).
 * Each entry maps a date to a power meter sample that combines data from all relevant sub-sample points.
 */
export const mergePowerMeterSamplesNewestFirst = (
  requestSamplePointIds: SamplePointId[],
  samplesFromAssociatedSamplePoints: Sample[][],
  mainSamplePoint: SamplePoint
): Map<number, PowerMeterSample> => {
  let flagsSamplePointId: SamplePointId;
  let energySamplePointId: SamplePointId;
  let line1VoltageSamplePointId: SamplePointId;
  let line1CurrentSamplePointId: SamplePointId;
  let line2VoltageSamplePointId: SamplePointId | undefined;
  let line2CurrentSamplePointId: SamplePointId | undefined;
  let line3VoltageSamplePointId: SamplePointId | undefined;
  let line3CurrentSamplePointId: SamplePointId | undefined;

  try {
    ({
      flagsSamplePointId,
      energySamplePointId,
      line1VoltageSamplePointId,
      line1CurrentSamplePointId,
      line2VoltageSamplePointId,
      line2CurrentSamplePointId,
      line3VoltageSamplePointId,
      line3CurrentSamplePointId
    } = getOriginalSamplePointIdsFromMergedPowerMeter(mainSamplePoint));
  } catch (error) {
    // Shape of inputs is not as expected, so we cannot guarantee proper output
    return new Map();
  }

  // ============================================================
  // STEP 1: Merge sub-samples by date, disregarding the chronological order
  // ============================================================
  const samplesByDateUnordered = new Map<SampleDate, PowerMeterSample>();
  samplesFromAssociatedSamplePoints.forEach((samplesOfOneSamplePoint, index) => {
    if (samplesOfOneSamplePoint.length === 0) {
      return;
    }

    let mergeKey: keyof PowerMeterSample;

    // The samples have samplePointId attached, but those might be different ones due to historical data.
    // But underlying they are the same sensor (indeed, BE queries data by samplePoint.sid, not by samplePoint.id).
    // That's why we rely on the requestSamplePoints that FE used to initiate the queries.
    switch (requestSamplePointIds[index]) {
      case flagsSamplePointId:
        mergeKey = 'flags';
        break;
      case energySamplePointId:
        mergeKey = 'energy';
        break;
      case line1VoltageSamplePointId:
        mergeKey = 'line1Voltage';
        break;
      case line1CurrentSamplePointId:
        mergeKey = 'line1Current';
        break;
      case line2VoltageSamplePointId:
        mergeKey = 'line2Voltage';
        break;
      case line2CurrentSamplePointId:
        mergeKey = 'line2Current';
        break;
      case line3VoltageSamplePointId:
        mergeKey = 'line3Voltage';
        break;
      case line3CurrentSamplePointId:
        mergeKey = 'line3Current';
        break;
      default:
        return;
    }

    samplesOfOneSamplePoint.forEach(sample => {
      const existingMergedResult = samplesByDateUnordered.get(sample.date) || { date: sample.date };
      samplesByDateUnordered.set(sample.date, { ...existingMergedResult, [mergeKey]: sample });
    });
  });

  // ============================================================
  // STEP 2: Sort the merged samples by date, newest first
  // ============================================================
  const datesArrayNewestFirst = Array.from(samplesByDateUnordered.keys()).sort((a, b) => b - a);
  const samplesByDateNewestFirst = new Map<SampleDate, PowerMeterSample>();
  datesArrayNewestFirst.forEach((date, dateIndex) => {
    const sample = samplesByDateUnordered.get(date);
    if (sample?.flags) {
      assertIsSampleWithKnownFlags(sample); // Assertion needed because TypeScript just can't infer despite the if
      handleLineValuesAgainstFlags(sample);
      samplesByDateNewestFirst.set(date, sample);
    } else if (sample && (
      sample.line1Voltage ||
      sample.line1Current ||
      sample.line2Voltage ||
      sample.line2Current ||
      sample.line3Voltage ||
      sample.line3Current
    )) {
      const flagsSample = searchForNearestPrecedingFlagsSample(samplesByDateUnordered, datesArrayNewestFirst, dateIndex);
      if (flagsSample) {
        // During a sample interval (with a distinct seqNo), a flags sample might be sent once,
        // but voltage samples, for instance, might have three sub-intervals. These three sub-intervals
        // will share the same seqNo, but for display purposes, only attach the seqNo to the latest sub-interval
        // (the one that has the same sample date as the flags sample). The fact that we had to search for
        // the flags sample means that the current sample does not occur in the latest sub-interval,
        // so its seqNo display is nullified.
        const flagsSampleCopied = { ...flagsSample, extraValues: { seqNo: null } };
        const sampleWithCopiedFlags = { ...sample, flags: flagsSampleCopied };
        handleLineValuesAgainstFlags(sampleWithCopiedFlags);
        samplesByDateNewestFirst.set(date, sampleWithCopiedFlags);
      }
    } else if (sample) {
      // No flags. No lines. Just energy.
      samplesByDateNewestFirst.set(date, sample);
    }
  });

  return samplesByDateNewestFirst;
};