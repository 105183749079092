import { MergedSamplePoint } from 'types/models/samplePoint';
import SamplePointStatistic, { MergedSamplePointStatistic } from 'types/models/samplePointsStatistic';
import getOriginalSamplePointIdsFromMergedSamplePoint from 'utils/associated-sample-points/get-original-sample-point-ids-from-merged-sample-point';
import { mergeSafetyCheckInSamplePointStatistics } from 'utils/associated-sample-points/merge-safety-check-in-sos';

export function getMergedStatisticForSafetyCheckIn(
  allStatistics: Record<number, SamplePointStatistic>,
  mainSamplePoint: MergedSamplePoint
): MergedSamplePointStatistic {
  const [mainSamplePointId, hiddenSamplePointId] = getOriginalSamplePointIdsFromMergedSamplePoint(mainSamplePoint);
  if (hiddenSamplePointId) {
    return mergeSafetyCheckInSamplePointStatistics(allStatistics[mainSamplePointId], allStatistics[hiddenSamplePointId]);
  }
  return allStatistics[mainSamplePoint.id];
}