import SamplePoint from 'types/models/samplePoint';
import percentage from 'utils/Math/percentage';
import { baseNumericalSort } from 'utils/sorters';

import { isTroughDirect } from '.';

export const getTroughCapacityReading = (sp: SamplePoint): string => {
  let capacityValue: number = 0;
  if (isTroughDirect(sp)) {
    capacityValue = sp.troughDirectStatistics?.capacity.value || 0;
  } else {
    const latestAverage = sp.troughStatistics?.latestAverage;
    const maxValue = sp.config.maxValue;
    capacityValue = percentage(latestAverage, maxValue);
  }
  return `${capacityValue}%`;
};

export const troughCapacitySorter = (a: SamplePoint, b: SamplePoint) => {
  const aCapacity = isTroughDirect(a)
    ? a.troughDirectStatistics?.capacity.value
    : percentage(a.troughStatistics?.latestAverage, a.config.maxValue);
  const bCapacity = isTroughDirect(b)
    ? b.troughDirectStatistics?.capacity.value
    : percentage(b.troughStatistics?.latestAverage, b.config.maxValue);
  return baseNumericalSort(aCapacity || 0, bCapacity || 0);
};
