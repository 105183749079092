export const blue = '#307fbe';
export const coolGrey = '#e1e3e5';
export const darkBlue = '#054b7a';
export const darkCoolGrey = '#263238';
export const darkOrange = '#c9943e';
export const darkRed = '#b52828';
export const darkPink = '#d30d8d';
export const disabledGrey = '#b0bec5';
export const green = '#27ae60';
export const lightCoolGrey = '#e6eaed';
export const lightGrey = '#fafafa';
export const lightYellow = '#f7df9a';
export const midCoolGrey = '#b3bec4';
export const midCoolGrey2 = '#4f636e';
export const orange = '#e0a53c';
export const pink = '#f40bac';
export const purple = '#7242ce';
export const red = '#db4439';
export const skyBlue = '#64cced';
export const white = '#fff';