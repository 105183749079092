import { PowerLine } from 'types/models/power-meter';
import Sample from 'types/models/sample';

import { type BitGroup } from './bit';
import { parsePowerMeterFlagsSample } from './flags-parser';
import { checkLinePresence } from './line-presence';

// ==============================
// INTERNAL HELPERS
// ==============================
const getCurrentOrVoltageSampleValueAgainstFlags = (
  lineBitGroups: { l1: BitGroup | null; l2: BitGroup | null; l3: BitGroup | null },
  lineNumber: PowerLine['lineNumber'],
  sampleValue: Sample['rwValue'] | undefined
) => {
  const { l1, l2, l3 } = lineBitGroups;
  switch (lineNumber) {
    // The `isActuallyPresent` flag does not matter because firmware only sends a zero sample for lines upon first boot.
    // In subsequent transmissions, it won't send zero readings. The UI must fill in the zero instead of leaving it
    // empty.
    case 1: {
      if (!l1) return null;
      const { isExpectedPresent } = checkLinePresence(l1);
      return isExpectedPresent ? (sampleValue ?? 0) : null;
    }
    case 2: {
      if (!l2) return null;
      const { isExpectedPresent } = checkLinePresence(l2);
      return isExpectedPresent ? (sampleValue ?? 0) : null;
    }
    case 3: {
      if (!l3) return null;
      const { isExpectedPresent } = checkLinePresence(l3);
      return isExpectedPresent ? (sampleValue ?? 0) : null;
    }
    default:
      return null;
  }
};

// ==============================
// EXPORTS
// ==============================
export const getCurrentValueAgainstFlags = (
  flagsSampleValue: Sample['rwValue'],
  lineNumber: PowerLine['lineNumber'],
  currentSampleValue: Sample['rwValue'] | undefined
): number | null => {
  const { l1c, l2c, l3c } = parsePowerMeterFlagsSample(flagsSampleValue) || {};
  const lineBitGroups = {
    l1: l1c ?? null,
    l2: l2c ?? null,
    l3: l3c ?? null
  };
  return getCurrentOrVoltageSampleValueAgainstFlags(lineBitGroups, lineNumber, currentSampleValue);
};

export const getVoltageValueAgainstFlags = (
  flagsSampleValue: Sample['rwValue'],
  lineNumber: PowerLine['lineNumber'],
  voltageSampleValue: Sample['rwValue'] | undefined
): number | null => {
  const { l1v, l2v, l3v } = parsePowerMeterFlagsSample(flagsSampleValue) || {};
  const lineBitGroups = {
    l1: l1v ?? null,
    l2: l2v ?? null,
    l3: l3v ?? null
  };
  return getCurrentOrVoltageSampleValueAgainstFlags(lineBitGroups, lineNumber, voltageSampleValue);
};