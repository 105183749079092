/* eslint-disable no-underscore-dangle */

import { AssetTypeCode, RawAssetTypeCode } from 'types/models/asset-type';
import Sample, { ExtendedSample } from 'types/models/sample';
import SamplePoint, { RawSamplePoint } from 'types/models/samplePoint';
import SamplePointStatistic from 'types/models/samplePointsStatistic';
import parseRawSamplePoint from 'utils/associated-sample-points/parse-raw-samplepoint';
import dateToISOString from 'utils/date-to-iso-string';

export const mergeSafetyCheckInSamplesChronologically = (
  checkInSamples: ExtendedSample[],
  sosSamples: ExtendedSample[]
): ExtendedSample[] => {
  // TODO: should not have any
  const mergedSamples: any[] = [];
  for (let i = 0; i < checkInSamples.length; i += 1) {
    const sample = checkInSamples[i];
    sample.rawAssetType = RawAssetTypeCode.SAFETY_CHECK_IN_VISIT;
    mergedSamples.push(sample);
  }
  for (let i = 0; i < sosSamples.length; i += 1) {
    const sample = sosSamples[i];
    sample.rawAssetType = RawAssetTypeCode.SAFETY_CHECK_IN_SOS;
    mergedSamples.push(sample);
  }
  return mergedSamples.sort((a, b) => a.date - b.date);
};

/**
 * Merge associated SCI (Safety Check-in) check-in & SOS samples into a single
 * sample.
 */
const mergeSafetyCheckInSamples = (
  checkInSample: Sample,
  sosSample: Sample
): Sample =>
  ({
    ...checkInSample,
    multiDimValues: {
      // Give each value a different dataType so we can tell them apart.
      sampleDim: [
        {
          ...checkInSample,
          dataType: RawAssetTypeCode.SAFETY_CHECK_IN_VISIT
        },
        {
          ...sosSample,
          dataType: RawAssetTypeCode.SAFETY_CHECK_IN_SOS
        }
      ].map(({ rwValue, dataType, date, prevDate }) => ({
        rwValue,
        dataType,
        sampleDate: dateToISOString(date),
        prevSampleDate: dateToISOString(prevDate)
      }))
    },
    // Keep original data of temp sample, so we can recreate it if needed.
    _hidden: sosSample
  }) as Sample;

/**
 * Takes a raw SCI samplePoint and a parsed SCI samplePoint, and merges them
 * into a single parsed samplePoint.
 */
const mergeSafetyCheckInSamplePoints = (
  rawSafetyCheckInSamplePoint: RawSamplePoint,
  safetyCheckInSamplePoint: SamplePoint
): SamplePoint => {
  const isSOS =
    rawSafetyCheckInSamplePoint.assetTypeId ===
    RawAssetTypeCode.SAFETY_CHECK_IN_SOS;

  const [sos, checkIn] = isSOS
    ? [
      parseRawSamplePoint(rawSafetyCheckInSamplePoint) as SamplePoint,
      safetyCheckInSamplePoint
    ]
    : [
      safetyCheckInSamplePoint,
      parseRawSamplePoint(rawSafetyCheckInSamplePoint) as SamplePoint
    ];
  return {
    ...checkIn,
    // Keep original data of SOS sample point, so we can recreate it if needed.
    _hidden: sos
  } as SamplePoint;
};

/**
 * Merge associated SCI check-in & SOS samplePointStatistics into a single
 * samplePointStatistic.
 */
export const mergeSafetyCheckInSamplePointStatistics = (
  checkIn: SamplePointStatistic,
  sos: SamplePointStatistic
) =>
  ({
    ...checkIn,
    lastSample:
      checkIn.lastSample && sos.lastSample
        ? mergeSafetyCheckInSamples(checkIn.lastSample, sos.lastSample)
        : checkIn.lastSample,
    _hidden: sos
  }) as SamplePointStatistic;

/**
 * Returns true if the given raw & parsed SCI samplePoints are associated.
 * That is, they have the same serial number (and are both SCI samplePoints).
 */
const isMatchingSafetyCheckInSamplePoint = (
  safetyCheckInSamplePoint: RawSamplePoint,
  samplePoint: SamplePoint
) =>
  (safetyCheckInSamplePoint.assetTypeId ===
    RawAssetTypeCode.SAFETY_CHECK_IN_VISIT ||
    safetyCheckInSamplePoint.assetTypeId ===
    RawAssetTypeCode.SAFETY_CHECK_IN_SOS) &&
  samplePoint.assetTypeId === AssetTypeCode.SAFETY_CHECK_IN &&
  // SCI sample point has no SN in their device tags so we use device id here.
  safetyCheckInSamplePoint.deviceId === samplePoint.deviceId;

/**
 * Takes an array of parsed samplePoints and a raw samplePoint, and
 * returns an array of parsed samplePoints.
 */
export const safetyCheckInSamplePointReducer = (
  accumulatedSamplePoints: SamplePoint[],
  currentSafetyCheckIn: RawSamplePoint
): SamplePoint[] => {
  const nextSamplePoints: SamplePoint[] = [];
  let merged = false;

  // Scan through the accumulated parsed sample points, if currentSafetyCheckIn
  // pairs with an accumulated sample point, merge them. Otherwise,
  // keep copying SP from accumulatedSamplePoints to nextSamplePoints array.
  for (let i = 0; i < accumulatedSamplePoints.length; i++) {
    const samplePoint = accumulatedSamplePoints[i];

    if (isMatchingSafetyCheckInSamplePoint(currentSafetyCheckIn, samplePoint)) {
      nextSamplePoints.push(
        mergeSafetyCheckInSamplePoints(currentSafetyCheckIn, samplePoint)
      );
      merged = true;
    } else {
      nextSamplePoints.push(samplePoint);
    }
  }
  // If currentSafetyCheckIn hasn't paired with any SP, parse it then add to the parsed sample points.
  if (!merged) {
    const parsedSafetyCheckIn = parseRawSamplePoint(currentSafetyCheckIn);
    if (parsedSafetyCheckIn) {
      nextSamplePoints.push(parsedSafetyCheckIn);
    }
  }

  return nextSamplePoints;
};
