import { RawAssetTypeCode } from 'types/models/asset-type';
import SamplePoint, { RawSamplePoint } from 'types/models/samplePoint';
import { powerMeterSamplePointReducer } from 'utils/associated-sample-points/merge-power-meters';
import { safetyCheckInSamplePointReducer } from 'utils/associated-sample-points/merge-safety-check-in-sos';
import { soilSamplePointReducer } from 'utils/associated-sample-points/merge-soil-moisture-temperature';
import parseRawSamplePoint from 'utils/associated-sample-points/parse-raw-samplepoint';

import { machineControlSamplePointReducer } from '../merge-machine-controls';

/**
 * Takes an array of raw samplePoints and returns an array of parsed
 * samplePoints. SamplePoints of unsupported asset types are removed.
 */
export const parseRawSamplePoints = (
  rawSamplePoints: RawSamplePoint[],
  country: string
): SamplePoint[] =>
  rawSamplePoints
    .reduce<SamplePoint[]>((acc, rawSP) => {
      switch (rawSP.assetTypeId) {
        case RawAssetTypeCode.SOIL_MOISTURE:
        case RawAssetTypeCode.SOIL_TEMP:
          return soilSamplePointReducer(acc, rawSP);
        case RawAssetTypeCode.SAFETY_CHECK_IN_SOS:
        case RawAssetTypeCode.SAFETY_CHECK_IN_VISIT:
          return safetyCheckInSamplePointReducer(acc, rawSP);
        case RawAssetTypeCode.MACHINE_CONTROL:
          return machineControlSamplePointReducer(acc, rawSP);
        case RawAssetTypeCode.POWER_METER_FLAGS:
        case RawAssetTypeCode.POWER_METER_VOLTAGE:
        case RawAssetTypeCode.POWER_METER_CURRENT:
        case RawAssetTypeCode.POWER_METER_ENERGY:
          return powerMeterSamplePointReducer(acc, rawSP);
        default: {
          const parsedSP = parseRawSamplePoint(rawSP, country);
          if (!parsedSP) return acc;
          return [...acc, parsedSP];
        }
      }
    }, []);