import { AxiosError } from 'axios';
import { LDSingleKindContext } from 'launchdarkly-react-client-sdk';
import { action } from 'typesafe-actions';

import { EnterpriseId } from 'types/models/enterprise';

import ActionTypes from './constants';

interface LDEnterprise extends LDSingleKindContext {
  kind: 'enterprise';
  name: string;
  country: string;
  industry: string;
  sensorTypes: number[];
  numberOfDevices: number;
  numberOfSensors: number;
  numberOfSites: number;
  createdAt: number;
}

export const loadEnterpriseScaffold = (
  enterpriseId: EnterpriseId,
  isInitialLoading?: boolean,
  onInitialLoadingSuccess?: (ldContext: LDEnterprise) => void
) =>
  action(ActionTypes.LOAD_ENTERPRISE_SCAFFOLD_REQUEST, {
    enterpriseId,
    isInitialLoading,
    onInitialLoadingSuccess
  });

export const loadEnterpriseScaffoldSuccess = () =>
  action(ActionTypes.LOAD_ENTERPRISE_SCAFFOLD_SUCCESS);

export const loadEnterpriseScaffoldFailure = (
  message: string,
  error?: AxiosError
) =>
  action(
    ActionTypes.LOAD_ENTERPRISE_SCAFFOLD_FAILURE,
    { message, error },
    undefined,
    true
  );
