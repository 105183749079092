import AuthActionTypes from 'store/modules/auth/constants';
import { ApplicationActions } from 'store/types';

import ActionTypes from './constants';
import { SamplesState } from './types';

export const initialState: SamplesState = {
  samplePointSamples: {},
  controlPointSamples: {},
  powerMeterSamples: new Map()
};

function samplesReducer(
  state: SamplesState = initialState,
  action: ApplicationActions
): SamplesState {
  switch (action.type) {
    case ActionTypes.SET_SAMPLE_POINT_SAMPLES: {
      const { samples, override } = action.payload;
      return {
        ...state,
        samplePointSamples: override ? samples : { ...state.samplePointSamples, ...samples }
      };
    }
    case ActionTypes.SET_CONTROL_POINT_SAMPLES:
      return {
        ...state,
        controlPointSamples: action.payload
      };
    case ActionTypes.SET_POWER_METER_SAMPLES:
      return {
        ...state,
        powerMeterSamples: action.payload
      };
    case AuthActionTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default samplesReducer;
