import { MergedSamplePoint } from 'types/models/samplePoint';
import SamplePointStatistic, { MergedSamplePointStatistic } from 'types/models/samplePointsStatistic';
import getOriginalSamplePointIdsFromMergedSamplePoint from 'utils/associated-sample-points/get-original-sample-point-ids-from-merged-sample-point';
import { mergeSoilSamplePointStatistics } from 'utils/associated-sample-points/merge-soil-moisture-temperature';
import { assertIsSamplePointStatisticWithAggregates } from 'utils/SamplePointStatistics/assert-is-with-aggregates';

export function getMergedStatisticForSoil(
  allStatistics: Record<number, SamplePointStatistic>,
  mainSamplePoint: MergedSamplePoint
): MergedSamplePointStatistic {
  const [mainSamplePointId, hiddenSamplePointId] = getOriginalSamplePointIdsFromMergedSamplePoint(mainSamplePoint);
  if (hiddenSamplePointId) {
    const moisture = allStatistics[mainSamplePointId];
    const temperature = allStatistics[hiddenSamplePointId];
    try {
      assertIsSamplePointStatisticWithAggregates(moisture);
      assertIsSamplePointStatisticWithAggregates(temperature);
    } catch (error) {
      return allStatistics[mainSamplePointId];
    }
    return mergeSoilSamplePointStatistics(moisture, temperature);
  }
  return allStatistics[mainSamplePointId];
}
